@import 'wl-typography-utils';

@mixin rd-core-side-nav-link-typography($base-typography, $tenant-typography) {
  .rd-core-side-nav-link {
    .dropdown-item {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-side-nav-link--dropdown-item-typeface',
        'text-emphasis-2-desktop'
      );
    }

    .dropdown-link-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-side-nav-link--dropdown-link-text-typeface',
        'text-desktop'
      );
    }
  }
}
