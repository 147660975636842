@import 'anz-typefaces';
@import 'rd-typography';
@import 'wl-typography-utils';
@import 'map-utils';

$base-typography: (
  // Desktop
  'heading-1-desktop': map-get($anz-typefaces, 'heading-40-semibold'),
  'heading-2-desktop': map-get($anz-typefaces, 'heading-30-semibold'),
  'heading-3-desktop': map-get($anz-typefaces, 'heading-24-semibold'),
  'emphasis-1-desktop': map-get($anz-typefaces, 'body-20-semibold'),
  'emphasis-2-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-3-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-4-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-5-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-6-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-7-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-8-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'emphasis-9-desktop': map-get($anz-typefaces, 'body-20-regular'),
  'text-desktop': map-get($anz-typefaces, 'body-16-regular'),
  'text-emphasis-1-desktop': map-get($anz-typefaces, 'body-16-semibold'),
  'text-emphasis-2-desktop': map-get($anz-typefaces, 'body-16-regular'),
  'small-text-desktop': map-get($anz-typefaces, 'small-text-14-regular'),
  'small-text-emphasis-1-desktop':
    map-get($anz-typefaces, 'small-text-14-semibold'),
  'small-text-emphasis-2-desktop':
    map-get($anz-typefaces, 'small-text-14-regular'),
  'caption-desktop': map-get($anz-typefaces, 'small-text-14-regular'),
  'caption-emphasis-desktop': map-get($anz-typefaces, 'small-text-14-semibold'),
  // Mobile
  'heading-1-mobile': map-get($anz-typefaces, 'heading-20-semibold'),
  'heading-2-mobile': map-get($anz-typefaces, 'heading-20-semibold'),
  'heading-3-mobile': map-get($anz-typefaces, 'heading-20-semibold'),
  'emphasis-1-mobile': map-get($anz-typefaces, 'body-20-semibold'),
  'emphasis-2-mobile': map-get($anz-typefaces, 'body-16-regular'),
  'emphasis-3-mobile': map-get($anz-typefaces, 'heading-24-semibold'),
  'emphasis-4-mobile': map-get($anz-typefaces, 'body-16-regular'),
  'emphasis-5-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'emphasis-6-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'emphasis-7-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'emphasis-8-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'emphasis-9-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'text-mobile': map-get($anz-typefaces, 'body-16-regular'),
  'text-emphasis-1-mobile': map-get($anz-typefaces, 'body-16-semibold'),
  'text-emphasis-2-mobile': map-get($anz-typefaces, 'body-16-regular'),
  'small-text-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'small-text-emphasis-1-mobile':
    map-get($anz-typefaces, 'small-text-14-semibold'),
  'small-text-emphasis-2-mobile':
    map-get($anz-typefaces, 'small-text-14-regular'),
  'caption-mobile': map-get($anz-typefaces, 'small-text-14-regular'),
  'caption-emphasis-mobile': map-get($anz-typefaces, 'small-text-14-semibold')
);

$core-module-typography: (
  'core-auth-session-expired-dialog--error-message-typeface-desktop':
    map-get($base-typography, 'emphasis-1-desktop'),
  'core-auth-session-expired-dialog--error-message-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
);

$shared-module-typography: (
  'rd-shared-counter--mat-input-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'rd-shared-counter--mat-input-typeface-mobile':
    map-get($base-typography, 'emphasis-3-mobile'),
  'rd-shared-fulfillment-processing-loader--processing-text-typeface-desktop':
    map-get($base-typography, 'heading-2-desktop'),
  'rd-shared-fulfillment-processing-loader--processing-text-typeface-mobile':
    map-get($base-typography, 'heading-2-mobile'),
  'rd-shared-fulfillment-processing-loader--wait-disclaimer-text-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'rd-shared-fulfillment-processing-loader--wait-disclaimer-text-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'rd-shared-link--typeface': map-get($base-typography, 'text-desktop'),
  'rd-shared-panel-accordion--body-typeface-desktop':
    map-get($base-typography, 'small-text-desktop'),
  'rd-shared-panel-accordion--body-typeface-mobile':
    map-get($base-typography, 'small-text-mobile'),
  'rd-shared-panel-accordion--title-typeface-desktop':
    map-get($anz-typefaces, 'heading-24-semibold'),
);

$gift-card-module-typography: (
  'gc-checkout-amount-form--amount-form-title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-checkout-amount-form--amount-form-title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-checkout-information--gift-card-name-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-checkout-information--gift-card-name-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-checkout-information--terms-and-conditions-text':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'gc-terms-and-condition-dialog-title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-checkout-physical-address-form--recipient-details-title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-checkout-physical-address-form--recipient-details-title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-checkout-recipient-detail-form--recipient-details-title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-checkout-recipient-detail-form--recipient-details-title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-gift-cards-list--empty-gift-cards-title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-gift-cards-list--empty-gift-cards-title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-review-body--title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-review-body--title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-review-breakdown--item-label-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'gc-review-breakdown--item-label-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'gc-success-detail--gift-card-currency-amount-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'gc-success-detail--gift-card-currency-amount-typeface-mobile':
    map-get($base-typography, 'text-emphasis-1-mobile'),
  'gc-success-detail--gift-card-product-name-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'gc-success-detail--gift-card-product-name-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'gc-success-points-transaction-status--gift-card-order-reference-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'gc-success-points-transaction-status--gift-card-order-reference-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'gc-success-points-transaction-status--gift-card-status-text-typeface-desktop':
    map-get($base-typography, 'heading-2-desktop'),
  'gc-success-points-transaction-status--gift-card-status-text-typeface-mobile':
    map-get($anz-typefaces, 'heading-24-semibold'),
);

$points-transaction-module-typography: (
  'pt-category-filter--dropdown-selected-text':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'pt-date-picker--button-typeface': map-get($base-typography, 'text-desktop'),
  'pt-date-picker--day-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'pt-date-picker--month-year-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'pt-date-range-input--input-section-input-label-typeface':
    map-get($base-typography, 'small-text-desktop'),
  'pt-date-range-input--main-input-typeface':
    map-get($base-typography, 'text-desktop'),
  'pt-history-list-desktop--history-list-header-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'pt-history-list-desktop--empty-points-transactions-title-typeface':
    map-get($base-typography, 'heading-3-desktop'),
  'pt-history-list-mobile--empty-points-transactions-title-typeface':
    map-get($base-typography, 'heading-3-mobile'),
  'pt-tile-title--title-text-typeface-desktop':
    map-get($base-typography, 'emphasis-1-desktop'),
  'pt-tile-title--title-text-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
);

$redeem-cash-module-typography: (
  'rcv2-checkout-banner--banner-subtitle-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'rcv2-checkout-form--title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'rcv2-checkout-form--title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'rcv2-checkout-terms-list--list-item-text-typeface':
    map-get($base-typography, 'text-desktop'),
  'rcv2-checkout-transfer-amount-form--mat-input-typeface':
    map-get($base-typography, 'heading-3-desktop'),
  'rcv2-link-account-detail-form--helper-message-typeface-desktop':
    map-get($base-typography, 'small-text-desktop'),
  'rcv2-link-account-detail-form--helper-message-typeface-mobile':
    map-get($base-typography, 'small-text-mobile'),
  'rcv2-membership-failed-dialog--description-typeface':
    map-get($base-typography, 'text-desktop'),
  'rcv2-membership-failed-dialog--title-typeface':
    map-get($base-typography, 'emphasis-1-desktop'),
  'rcv2-membership-success-dialog--description-typeface':
    map-get($base-typography, 'text-desktop'),
  'rcv2-membership-success-dialog--title-typeface':
    map-get($base-typography, 'emphasis-1-desktop'),
  'rcv2-review-body--helper-text-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'rcv2-review-body--redeem-account-text-typeface-mobile':
    map-get($base-typography, 'small-text-emphasis-1-mobile'),
  'rcv2-review-body--title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'rcv2-review-breakdown--list-item-amount-typeface-mobile':
    map-get($base-typography, 'small-text-emphasis-1-mobile'),
  'rcv2-review-breakdown--list-item-description-typeface-mobile':
    map-get($base-typography, 'small-text-mobile'),
  'rcv2-success-points-transaction-status--order-id-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'rcv2-success-points-transaction-status--order-id-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'rcv2-success-points-transaction-status--status-text-typeface-desktop':
    map-get($base-typography, 'heading-2-desktop'),
  'rcv2-success-points-transaction-status--status-text-typeface-mobile':
    map-get($anz-typefaces, 'heading-24-semibold'),
  'rd-rcv2-link-account-form--enrollment-group-reference-id-typeface':
    map-get($base-typography, 'text-desktop'),
  'rd-rcv2-link-account-form--enrollment-product-type-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
);

$transfer-connect-module-typography: (
  'tcv2-checkout-form--main-text-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'tcv2-checkout-form--main-text-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'tcv2-checkout-transfer-amount-form--mat-input-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'tcv2-checkout-transfer-amount-form--mat-input-typeface-mobile':
    map-get($base-typography, 'emphasis-3-mobile'),
  'tcv2-checkout-transfer-amount-form--amount-label-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'tcv2-checkout-transfer-amount-form--amount-label-typeface-mobile':
    map-get($base-typography, 'text-emphasis-1-mobile'),
  'tcv2-checkout-program-description--loyalty-program-name-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'tcv2-checkout-program-description--loyalty-program-name-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'tcv2-membership-fail-dialog--title-typeface-desktop':
    map-get($base-typography, 'emphasis-1-desktop'),
  'tcv2-membership-fail-dialog--title-typeface-mobile':
    map-get($base-typography, 'emphasis-1-mobile'),
  'tcv2-membership-success-dialog--title-typeface':
    map-get($base-typography, 'emphasis-1-desktop'),
  'tcv2-review-body--title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'tcv2-review-body--title-typeface-mobile':
    map-get($base-typography, 'heading-3-mobile'),
  'tcv2-success-points-transaction-status--redemption-id-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'tcv2-success-points-transaction-status--redemption-id-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'tcv2-success-points-transaction-status--status-text-typeface-desktop':
    map-get($base-typography, 'heading-2-desktop'),
  'tcv2-success-points-transaction-status--status-text-typeface-mobile':
    map-get($anz-typefaces, 'heading-24-semibold'),
);

$popup-module-typography: (
  'rd-popup-default-template--title-typeface-mobile':
    map-get($base-typography, 'emphasis-3-mobile'),
);

$static-pages-module-typography: (
  'static-pages-contact-us--title-typeface-desktop':
    map-get($base-typography, 'heading-3-desktop'),
  'static-pages-contact-us--title-typeface-mobile':
    map-get($base-typography, 'heading-1-mobile'),
);

$auto-redemption-view-module-typography: (
  'auto-redemption-date-picker--button-typeface':
    map-get($base-typography, 'text-desktop'),
  'auto-redemption-date-picker--day-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'auto-redemption-date-picker--month-year-typeface':
    map-get($base-typography, 'text-emphasis-1-desktop'),
);

$payment-module-typography: (
  'payment-card-detail-form--save-card-label-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'payment-card-detail-form--save-card-label-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
);

$tenant-typography: map-collect(
  $core-module-typography,
  $shared-module-typography
);

@include generate-typography-classes($base-typography);
@include rd-core-module-typography($base-typography, $tenant-typography);
@include rd-shared-module-typography($base-typography, $tenant-typography);

:root {
  @include generate-typography-variable-from-map(
    $base-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  // General typography
  --text-typeface: 400 1rem/1.5 'MyriadPro, Arial, Helvetica, sans-serif';
  --text-mobile-typeface: 400 1rem/1.5 'MyriadPro, Arial, Helvetica, sans-serif';

  // Auto Redemption Date Picker
  @include generate-typography-variable-from-map(
    $auto-redemption-view-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $points-transaction-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $gift-card-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $static-pages-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  // RCv2 Typography
  @include generate-typography-variable-from-map(
    $redeem-cash-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  // Payment typography
  @include generate-typography-variable-from-map(
    $payment-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  // Transfer Connect V2 typography
  @include generate-typography-variable-from-map(
    $transfer-connect-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );

  // Popup typography
  @include generate-typography-variable-from-map(
    $popup-module-typography,
    'MyriadPro, Arial, Helvetica, sans-serif'
  );
}
