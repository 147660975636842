@import 'wl-tenant-variables-utils';

@mixin rd-button-height($tenant-variables) {
  $button-height: rd-apply-tenant-variables(
    $tenant-variables,
    'button-height',
    (56px)
  );

  .rd-shared-button,
  .button-height {
    height: $button-height;
  }
}
