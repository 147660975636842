@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-alert-typography($base-typography, $tenant-typography) {
  .rd-shared-alert {
    @include rd-typography-style(
      $base-typography,
      $tenant-typography,
      'shared-alert--text-typeface-mobile',
      'text-mobile'
    );

    @include screen-from-desktop {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'shared-alert--text-typeface-desktop',
        'text-desktop'
      );
    }

    .emphasis {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'shared-alert--text-typeface-mobile',
        'text-emphasis-1-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'shared-alert--text-typeface-desktop',
          'text-emphasis-1-desktop'
        );
      }
    }
  }
}
