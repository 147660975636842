@import 'wl-color-utils';

@mixin rd-core-auth-secure-route-dialog-theme($color-theme, $component-theme) {
  $core-auth-secure-route-dialog--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-auth-secure-route-dialog--text-color,
    'text'
  );
  $core-auth-secure-route-dialog--link-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-auth-secure-route-dialog--link-color,
    'link-100'
  );

  .rd-core-auth-secure-route-dialog {
    color: $core-auth-secure-route-dialog--text-color;

    .register-link {
      color: $core-auth-secure-route-dialog--link-color;
    }
  }
}
