@import 'wl-color-utils';

@mixin rd-core-main-header-theme($color-theme, $component-theme) {
  $core-main-header--dropdown-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--dropdown-background-color,
    primary-300
  );
  $core-main-header--dropdown-loading-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--dropdown-loading-background-color,
    primary-100
  );
  $core-main-header--focus-outline-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--focus-outline-color,
    focus
  );
  $core-main-header--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--text-color,
    grey-300
  );
  $core-main-header--hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--hover-color,
    grey-100
  );
  $core-main-header--gradient-start-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--gradient-start-color,
    primary-400
  );
  $core-main-header--gradient-end-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--gradient-end-color,
    primary-300
  );
  $core-main-header--mobile-back-button-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--mobile-back-button-color,
    'background'
  );
  $core-main-header--toggle-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--toggle-background-color,
    shadow
  );
  $core-main-header--user-balance-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--user-balance-border-color,
    primary-200
  );
  $core-main-header--user-balance-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--user-balance-background-color,
    shadow
  );
  $core-main-header--notif-icon-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--notif-icon-background-color,
    error
  );
  $core-main-header--header-icon-hover-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--header-icon-hover-background-color,
    primary-200
  );
  $core-main-header--notif-button-mobile-focus-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-main-header--notif-button-mobile-focus-border-color,
    focus
  );

  .rd-core-main-header {
    .header {
      background: linear-gradient(
        to right,
        $core-main-header--gradient-start-color 1%,
        $core-main-header--gradient-end-color 87%
      );
    }

    .mobile-back-button-image {
      color: $core-main-header--mobile-back-button-color;
    }

    .header-link {
      color: $core-main-header--text-color;
    }

    .header-dropdown-container {
      .toggle-button {
        background-color: rgba(
          $core-main-header--toggle-background-color,
          0.35
        );
        color: $core-main-header--text-color;

        &:focus {
          outline-color: $core-main-header--focus-outline-color;
        }
      }

      .user-balance-container {
        background-color: rgba(
          $core-main-header--user-balance-background-color,
          0.35
        );
        border-color: $core-main-header--user-balance-border-color;
      }
    }

    .dropdown {
      background-color: rgba(
        $core-main-header--dropdown-background-color,
        0.85
      );
    }

    .dropdown-loading {
      background-color: $core-main-header--dropdown-loading-background-color;
    }

    .notif-icon {
      &:after {
        background-color: $core-main-header--notif-icon-background-color;
      }
    }

    .header-icon-image {
      &:hover {
        background-color: $core-main-header--header-icon-hover-background-color;
      }
    }

    .notif-button-mobile {
      &:focus {
        border-color: $core-main-header--notif-button-mobile-focus-border-color;
      }
    }
  }
}
