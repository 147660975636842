@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-breadcrumb-typography($base-typography, $tenant-typography) {
  .rd-shared-breadcrumb {
    .breadcrumb-link {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'shared-breadcrumb--breadcrumb-link-typeface-desktop',
        'text-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'shared-breadcrumb--breadcrumb-link-typeface-mobile',
          'text-mobile'
        );
      }
    }
  }
}
