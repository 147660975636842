@import 'wl-color-utils';

@mixin rd-core-header-dropdown-link-theme($color-theme, $component-theme) {
  $core-header-dropdown-link--dropdown-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-dropdown-link--dropdown-background-color,
    primary-300
  );
  $core-header-dropdown-link--dropdown-focus-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-dropdown-link--dropdown-focus-background-color,
    primary-200
  );
  $core-header-dropdown-link--focus-outline-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-dropdown-link--focus-outline-color,
    focus
  );
  $core-header-dropdown-link--dropdown-not-last-child-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-dropdown-link--dropdown-not-last-child-border-color,
    primary-300
  );
  $core-header-dropdown-link--dropdown-link-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-dropdown-link--dropdown-link-text-color,
    'background'
  );

  .rd-core-header-dropdown-link {
    .dropdown-item {
      background-color: rgba(
        $core-header-dropdown-link--dropdown-background-color,
        0.85
      );

      &:focus {
        outline-color: $core-header-dropdown-link--focus-outline-color;
      }

      &:hover,
      &:focus {
        background-color: rgba(
          $core-header-dropdown-link--dropdown-focus-background-color,
          0.85
        );
      }

      &:active {
        background-color: rgba(
          $core-header-dropdown-link--dropdown-focus-background-color,
          0.75
        );
      }

      &:not(:last-child) {
        border-color: $core-header-dropdown-link--dropdown-not-last-child-border-color;
      }
    }

    .dropdown-link-text {
      color: $core-header-dropdown-link--dropdown-link-text-color;
    }
  }
}
