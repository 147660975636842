@import 'wl-typography-utils';

@mixin rd-core-header-locale-select-mobile-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-core-header-locale-select-mobile {
    .select-element {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-header-locale-select-mobile--select-element-typeface',
        'text-emphasis-2-desktop'
      );
    }
  }
}
