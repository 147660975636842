:root {
  $checkbox-dimension: 16px;
  $ripple-dimension: 40px;

  .mat-mdc-checkbox .mdc-checkbox {
    width: $checkbox-dimension;
    height: $checkbox-dimension;
    padding: 0px;

    .mdc-checkbox__background {
      width: $checkbox-dimension;
      height: $checkbox-dimension;
      top: 0px;
      left: 0px;
    }

    .mdc-checkbox__native-control,
    .mdc-checkbox__ripple,
    .mat-ripple {
      width: $ripple-dimension;
      height: $ripple-dimension;
      top: calc(($ripple-dimension - $checkbox-dimension) / -2);
      left: calc(($ripple-dimension - $checkbox-dimension) / -2);
    }

    .mat-mdc-checkbox-touch-target {
      width: $ripple-dimension;
      height: $ripple-dimension;
    }
  }

  .mat-mdc-checkbox {
    .mdc-form-field label {
      margin-left: 2px;
    }
  }
}

.ng-submitted .mat-mdc-checkbox.ng-invalid {
  .mdc-checkbox__background {
    border-color: var(--mat-checkbox--invalid-color, var(--error));
  }
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox {
  // TODO: Adding 'mat-checkbox--checked-color': map-get($color-theme, 'primary-300') for all WLs
  // Checkbox
  --mdc-checkbox-selected-icon-color: var(--mat-checkbox--checked-color);
  --mdc-checkbox-selected-focus-icon-color: var(--mat-checkbox--checked-color);
  --mdc-checkbox-unselected-focus-icon-color: var(
    --mat-checkbox--checked-color
  );
  --mdc-checkbox-selected-hover-icon-color: var(--mat-checkbox--checked-color);
  --mdc-checkbox-unselected-hover-icon-color: var(
    --mat-checkbox--checked-color
  );
  --mdc-checkbox-selected-pressed-icon-color: var(
    --mat-checkbox--checked-color
  );
  --mdc-checkbox-unselected-pressed-icon-color: var(
    --mat-checkbox--checked-color
  );
  --mdc-checkbox-selected-checkmark-color: var(
    --mat-checkbox--checkmark-color,
    var(--white)
  );
}
