// See ascenda-go-typefaces, fab-typefaces
// Given a typeface hash (with a name) and a set of font weights, creates
// title-1-light, title-1-regular, title-1-bold, text-light, text-regular, text-bold etc.
@function build-typefaces-from-config(
  $typefaces,
  $font-weights,
  $font-families: ()
) {
  $result: ();

  @each $typeface-name, $typeface in $typefaces {
    @each $font-weight-name, $font-weight in $font-weights {
      $value: map-merge(
        $typeface,
        (
          'font-weight': $font-weight,
        )
      );

      @if (length(map-keys($font-families)) > 0) {
        @each $font-family-name, $font-family in $font-families {
          $value: map-merge(
            $value,
            (
              'font-family': $font-family,
            )
          );

          $result: map-merge(
            $result,
            (
              '#{$typeface-name}-#{$font-weight-name}-#{$font-family-name}':
                $value,
            )
          );
        }
      } @else {
        $result: map-merge(
          $result,
          (
            '#{$typeface-name}-#{$font-weight-name}': $value,
          )
        );
      }
    }
  }

  @return $result;
}

/* stylelint-disable font-weight-notation */
@mixin rd-typography-style(
  $base-typography,
  $tenant-typography,
  $key,
  $base-typography-key
) {
  @if map-has-key($tenant-typography, $key) {
    $tenant-typeface: map-get($tenant-typography, $key);

    font-size: map-get($tenant-typeface, 'font-size');
    line-height: map-get($tenant-typeface, 'line-height');
    font-weight: map-get($tenant-typeface, 'font-weight');

    @if map-has-key($tenant-typeface, 'font-family') {
      font-family: map-get($tenant-typeface, 'font-family');
    }
  } @else {
    $base-typography-typeface: map-get($base-typography, $base-typography-key);

    @if map-has-key($base-typography, $base-typography-key) {
      font-size: map-get($base-typography-typeface, 'font-size');
      line-height: map-get($base-typography-typeface, 'line-height');
      font-weight: map-get($base-typography-typeface, 'font-weight');

      @if map-has-key($base-typography-typeface, 'font-family') {
        font-family: map-get($base-typography-typeface, 'font-family');
      }
    } @else {
      @error 'No key #{$base-typography-key} found in base typography theme';
    }
  }
}
/* stylelint-enable font-weight-notation */

// Given a base typography, a base typography key, and an override hash, get the base typography value
// and create a new typeface with the overrides.
// Use this to create new typefaces everything in base-typography is not enough
@function build-typeface-from-existing(
  $base-typography,
  $base-typography-key,
  $override-hash: ()
) {
  @if map-has-key($base-typography, $base-typography-key) {
    @return map-merge(
      map-get($base-typography, $base-typography-key),
      $override-hash
    );
  } @else {
    @error 'No key #{$base-typography-key} found in base typography theme';
  }
}

// add more breakpoints if needed
$breakpoints: (
  'base': '',
  'lg': 1024px,
);

/* stylelint-disable font-weight-notation */
@mixin generate-typography-classes($base-typography) {
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    @each $typeface-name, $typeface in $base-typography {
      @if $breakpoint-name == 'base' {
        .typo-#{$typeface-name} {
          font-size: map-get($typeface, 'font-size');
          line-height: map-get($typeface, 'line-height');
          font-weight: map-get($typeface, 'font-weight');
        }
      } @else {
        @media (min-width: $breakpoint-value) {
          .#{$breakpoint-name}\:typo-#{$typeface-name} {
            font-size: map-get($typeface, 'font-size');
            line-height: map-get($typeface, 'line-height');
            font-weight: map-get($typeface, 'font-weight');
          }
        }
      }
    }
  }
}
/* stylelint-enable font-weight-notation */

/* stylelint-disable font-weight-notation */
@mixin generate-typography-variable-from-map($map, $font-name: 'sans-serif') {
  @each $key, $value in $map {
    $mix-font-size: map-get($value, 'font-size');
    $mix-line-height: map-get($value, 'line-height');
    $mix-font-weight: map-get($value, 'font-weight');
    $mix-font-face: map-get($value, 'font-family');

    $font-face: $font-name;

    @if $mix-font-face != null {
      $font-face: $mix-font-face;
    }
    // The standard of CSS typography variable is: font-weight font-size/line-height font-family
    // e.g: --key-name: 400 16px/24px sans-serif;
    --#{$key}: #{$mix-font-weight}
      #{$mix-font-size}/#{$mix-line-height}
      #{$font-face};
  }
}
/* stylelint-enable font-weight-notation */
