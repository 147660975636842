@import 'wl-color-utils';

@mixin rd-anz-button-theme($color-theme, $component-theme) {
  $button-types: 'primary', 'secondary';

  $button-background-colors: (
    'primary': $link,
    'secondary': transparent,
  );

  $button-text-colors: (
    'primary': $white,
    'secondary': $link,
  );

  $button-hover-background-colors: (
    'primary': $link-hover,
    'secondary': transparent,
  );

  $button-hover-text-colors: (
    'primary': $white,
    'secondary': $link-hover,
  );

  $button-disabled-background-colors: (
    'primary': $grey-300,
    'secondary': transparent,
  );

  $button-disabled-text-colors: (
    'primary': $grey-600,
    'secondary': $grey-600,
  );

  $button-active-background-colors: (
    'primary': $blue-400,
    'secondary': transparent,
  );

  $button-active-text-colors: (
    'primary': $white,
    'secondary': $blue-400,
  );

  $button-focus-background-colors: (
    'primary': $link-hover,
    'secondary': transparent,
  );

  $button-focus-text-colors: (
    'primary': $white,
    'secondary': $link-hover,
  );

  .rd-shared-button .rd-button {
    @each $button-type in $button-types {
      &.#{$button-type} {
        background-color: map-get($button-background-colors, $button-type);
        .rd-button-text {
          color: map-get($button-text-colors, $button-type);
        }
      }

      &.#{$button-type}:hover {
        background-color: map-get(
          $button-hover-background-colors,
          $button-type
        );
        opacity: 1;
        .rd-button-text {
          color: map-get($button-hover-text-colors, $button-type);
        }
      }

      &.#{$button-type}:disabled {
        background-color: map-get(
          $button-disabled-background-colors,
          $button-type
        );
        .rd-button-text {
          color: map-get($button-disabled-text-colors, $button-type);
        }
      }

      &.#{$button-type}:active {
        background-color: map-get(
          $button-active-background-colors,
          $button-type
        );
        opacity: 1;
        .rd-button-text {
          color: map-get($button-active-text-colors, $button-type);
        }
      }

      &.#{$button-type}:focus:not(:disabled) {
        background-color: map-get(
          $button-focus-background-colors,
          $button-type
        );
        opacity: 1;
        .rd-button-text {
          color: map-get($button-focus-text-colors, $button-type);
        }
      }
    }

    &.primary {
      &:hover,
      &:focus:not(:disabled) {
        text-decoration: underline;
        text-decoration-color: $white;
      }

      &:disabled {
        text-decoration: none;
      }
    }

    &.secondary {
      border: 1px solid $link;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-color: $link-hover;
      }

      &:focus {
        box-shadow: $blue-700 0 0 0 3px;
      }

      &:disabled {
        border: 1px solid $grey-300;
        text-decoration: none;
      }
    }

    &.outline-primary {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-color: $white;
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
      }
    }
  }

  .rd-shared-button {
    &.outline-primary {
      background-color: transparent;
      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: transparent;
      }

      .rd-button {
        background-color: transparent;

        /* stylelint-disable max-nesting-depth */
        &:hover:not(:disabled),
        &:active:not(:disabled) {
          background-color: transparent;
        }
        /* stylelint-enable max-nesting-depth */
      }
    }
  }
}
