@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-keyword-filter-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-shared-keyword-filter {
    .keyword-search {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-keyword-filter--keyword-search-typeface',
        'text-desktop'
      );
    }
  }
}
