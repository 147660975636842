@import 'wl-tenant-variables-utils';

@mixin rd-mat-input($tenant-variables) {
  $default-mat-input-style: (
    'hint-and-error-font-size': (
      0.75rem,
    ),
    'label-scale': (
      0.75,
    ),
    'label-side-padding': (
      4px,
    ),
    'label-top-padding': (
      1.5rem,
    ),
    'label-vertical-offset': (
      -1.75rem,
    ),
  );

  $mat-input-style: rd-apply-tenant-variables(
    $tenant-variables,
    'mat-input',
    $default-mat-input-style
  );

  // Increase font-size of all material labels to 14px for accessibility purposes.
  // Because we have increased the font-size, the vertical offset (set in material) has to change as well -and it depends on the font-size.

  .rd-core-main-container {
    .mat-mdc-form-field-hint,
    .mat-mdc-form-field-error {
      font-size: map-get($mat-input-style, 'hint-and-error-font-size');
      line-height: 1;
      letter-spacing: 0;
    }

    // Form input labels should be kept consistent across the app
    input.mat-mdc-input-element,
    .mat-mdc-floating-label > mat-label {
      line-height: 1;
      letter-spacing: 0;
    }

    [class*='mat-form-field-appearance-'] .mat-mdc-floating-label {
      &.mdc-floating-label--float-above {
        font-size: 1rem;
        line-height: 1.125rem;
        letter-spacing: 0;

        --mat-mdc-form-field-label-transform: translateY(
            #{map-get($mat-input-style, 'label-vertical-offset')}
          )
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
      }
    }

    // Re-align empty text placeholder vertically as the font-size has changed. Tenant variable as this is font-family dependent.

    .mat-form-field-hide-placeholder {
      .mat-mdc-floating-label > mat-label {
        top: map-get($mat-input-style, 'label-top-padding');
      }
    }

    // Because we increased the font-size of the label, the outline is now blocked by the text.
    // This is a tenant-variable because different fonts need different spacings to look nice.
    // Cannot be scoped inside main-container as need LTR and RTL access.
    .ltr {
      .mat-mdc-floating-label > mat-label {
        padding-right: map-get($mat-input-style, 'label-side-padding');
      }
    }

    .rtl {
      .mat-mdc-floating-label > mat-label {
        padding-left: map-get($mat-input-style, 'label-side-padding');
      }
    }

    [class*='mat-form-field-appearance-'] {
      .mdc-text-field--outlined .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-top-left-radius: if(
            map-has-key($mat-input-style, 'border-radius'),
            map-get($mat-input-style, 'border-radius'),
            5px
          );
          border-bottom-left-radius: if(
            map-has-key($mat-input-style, 'border-radius'),
            map-get($mat-input-style, 'border-radius'),
            5px
          );
        }

        .mdc-notched-outline__trailing {
          border-top-right-radius: if(
            map-has-key($mat-input-style, 'border-radius'),
            map-get($mat-input-style, 'border-radius'),
            5px
          );
          border-bottom-right-radius: if(
            map-has-key($mat-input-style, 'border-radius'),
            map-get($mat-input-style, 'border-radius'),
            5px
          );
        }
      }
    }

    .mat-mdc-form-field {
      --mat-mdc-form-field-floating-label-scale: #{map-get(
          $mat-input-style,
          'label-scale'
        )};
    }
  }
}
