/* You can add global styles to this file, and also import other style files */
@use 'tailwindcss/utilities';
@import url(sanitize.css);
@import url(pikaday/css/pikaday.css);
@import 'spacing-scale';
@import 'color_variables';
@import 'mixins';

// RD-specific Tailwind declarations go here

.button-reset {
  @apply appearance-none border-0 bg-transparent p-0 hover:cursor-pointer active:cursor-pointer;

  &[disabled],
  &[aria-disabled='true'] {
    @apply hover:cursor-not-allowed active:cursor-not-allowed;
  }
}

@layer utilities {
  .screen-max-size {
    @include screen-max-size();
  }
  .padding-until-widescreen {
    @include padding-until-widescreen();
  }
}

// End RD-specific Tailwind declarations

body {
  font-size: 16px;
}

html[lang='ko-KR'] {
  // Hyundai uses Korean character, this avoid breaking CJK words
  // - https://kaligo.atlassian.net/browse/RD-2155
  // Tailwind does not support "keep-all"
  // - https://tailwindcss.com/docs/word-break
  word-break: keep-all;
}

html,
body {
  &.lock-body {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
}

/**
 * Below are extraction from the Tailwind base to support for `tranform`, `filter` utilities.
 * Without these settings, the utility classes are not working
 * e.g: rotate-180, drop-shadow-lg
 * Note: We do not support the `border-opacity` as it may impact existing border styles
 */
*,
::before,
::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty, /*!*/ /*!*/);
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.mat-placeholder-required.mat-form-field-required-marker {
  display: none;
}
