@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-core-page-not-found-typography($base-typography, $tenant-typography) {
  .rd-core-page-not-found {
    .page-not-found-header {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-page-not-found--page-not-found-header-typeface',
        'heading-2-desktop'
      );
    }

    .page-not-found-description {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-page-not-found--page-not-found-description-typeface',
        'text-desktop'
      );
    }
  }
}
