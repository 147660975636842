@import 'mixins';
@import 'variables';

// Tab navbar
.mat-mdc-tab-nav-bar {
  width: 100%;

  .mat-mdc-tab-header-pagination {
    display: none !important;
  }

  .mat-mdc-tab-link-container {
    overflow: hidden !important;

    .mat-mdc-tab-list .mat-mdc-tab-links {
      @include screen-until-desktop {
        display: flex;
        align-items: center;
      }

      .mat-mdc-tab-link {
        padding: 0px 10px;
        height: 40px;

        @include screen-from-desktop {
          max-width: 180px;
        }

        .mdc-tab__content {
          display: flex;
          align-items: flex-start;
        }
      }

      .mat-mdc-tab-link {
        color: inherit;
        text-decoration: none;

        .mdc-tab__text-label {
          color: inherit;
        }
      }

      .mat-mdc-tab-link .mdc-tab__ripple::before {
        content: none;
      }
    }
  }

  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    height: 4px;
    border-radius: 2px;
  }
}

.mat-mdc-tab-group .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-top-width: 4px;
  }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-basis: 0;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: var(--mat-tab-label--color, var(--link-100)) !important;
}

mat-tab-group.mat-mdc-tab-group mat-tab-header.mat-mdc-tab-header {
  --mat-mdc-tab-header-with-background-background-color: transparent;
  --mat-mdc-tab-header-with-background-foreground-color: var(
    --mat-tab-highlight--color,
    var(--primary-100)
  );
}

.rd-core-main-container {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    letter-spacing: normal;
    font: var(--rd-mat-tabs--typeface-mobile, var(--text-mobile));

    @screen lg {
      font: var(--rd-mat-tabs--typeface-desktop, var(--text-desktop));
    }

    &.mdc-tab-indicator--active {
      font: var(
        --rd-mat-tabs--active-typeface-mobile,
        var(--text-emphasis-1-mobile)
      );

      @screen lg {
        font: var(
          --rd-mat-tabs--active-typeface-desktop,
          var(--text-emphasis-1-desktop)
        );
      }
    }
  }
}
