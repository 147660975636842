@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-bottom-bar-typography($base-typography, $tenant-typography) {
  .rd-shared-bottom-bar {
    .action-button-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-bottom-bar--typeface',
        'text-mobile'
      );
    }
  }
}
