@import 'wl-color-utils';

@mixin rd-shared-link-theme($color-theme, $component-theme) {
  $shared-link--focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-link--focus-color,
    focus
  );
  $shared-link--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-link--text-color,
    link-100
  );
  $shared-link--text-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-link--text-active-color,
    primary-300
  );
  $shared-link--text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-link--text-hover-color,
    primary-300
  );

  .rd-shared-link {
    .link {
      color: $shared-link--text-color;

      &:focus {
        outline-color: $shared-link--focus-color;
      }

      &:active {
        .link-text {
          color: $shared-link--text-active-color;
        }
      }

      &:hover {
        .link-text {
          color: $shared-link--text-hover-color;
        }
      }
    }
  }
}
