@import 'wl-color-utils';

@mixin rd-shared-counter-theme($color-theme, $component-theme) {
  $rd-shared-counter--plus-minus-icon-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rd-shared-counter--plus-minus-icon-bg-color,
    primary-300
  );
  $rd-shared-counter--plus-minus-icon-fill-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rd-shared-counter--plus-minus-icon-fill-color,
    primary-100
  );
  $rd-shared-counter--plus-minus-icon-disabled-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rd-shared-counter--plus-minus-icon-disabled-bg-color,
    grey-200
  );
  $rd-shared-counter--plus-minus-icon-disabled-fill-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rd-shared-counter--plus-minus-icon-disabled-fill-color,
    grey-500
  );

  .rd-shared-counter {
    .rd-shared-icon.plus-icon,
    .rd-shared-icon.minus-icon {
      background: $rd-shared-counter--plus-minus-icon-bg-color;
      .icon-fill {
        fill: $rd-shared-counter--plus-minus-icon-fill-color;
      }

      &.disabled {
        background: $rd-shared-counter--plus-minus-icon-disabled-bg-color;
        .icon-fill {
          fill: $rd-shared-counter--plus-minus-icon-disabled-fill-color;
        }
      }
    }

    .rd-shared-icon.icon-active {
      &.plus-icon:active,
      &.minus-icon:active {
        background-color: rgba(
          $rd-shared-counter--plus-minus-icon-bg-color,
          0.5
        );
      }

      &.plus-icon.disabled:active,
      &.minus-icon.disabled:active {
        background-color: rgba(
          $rd-shared-counter--plus-minus-icon-disabled-bg-color,
          0.5
        );
      }
    }
  }
}
