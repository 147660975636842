@import 'wl-color-utils';

@mixin rd-core-page-not-found-theme($color-theme, $component-theme) {
  $core-page-not-found--header-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-page-not-found--header-color,
    primary-400
  );
  $core-page-not-found--description-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-page-not-found--description-color,
    grey-500
  );

  .rd-core-page-not-found {
    .page-not-found-header {
      color: $core-page-not-found--header-color;
    }

    .page-not-found-description {
      color: $core-page-not-found--description-color;
    }
  }
}
