// Must override at this level for ANZ and ANZ-BIZ as rd-shared-icon warn for all other parts of the app are "primary" color, but for the alert only must be "warn" color

.main-container {
  .rd-shared-alert.alert-warn {
    .rd-shared-icon {
      .icon-path {
        stroke: map-get($color-theme, 'warn');
      }

      .icon-fill {
        fill: map-get($color-theme, 'warn');
      }
    }
  }

  .rd-shared-alert.alert-error {
    .rd-shared-icon {
      .icon-path {
        stroke: map-get($color-theme, 'error');
      }

      .icon-fill {
        fill: map-get($color-theme, 'error');
      }
    }
  }
}

// As the autocomplete content is out of main-container so we need to move it into root
.address-autocomplete-panel,
.address-autocomplete-option {
  --address-autocomplete-option-color: #0072ac;
  --address-autocomplete-selected-option-bg: #f0faff;
  --address-autocomplete-selected-option-color: #004165;
}

.redeem-cash-checkout-title + p,
.setup-auto-redemp-title {
  color: #004165;
}
