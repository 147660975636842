$white: #ffffff;
$black: #000;

$blue-100: #f0faff; // BG sky
$blue-200: #eef2f6; // BG Calculator
$blue-300: #007dba;
$blue-400: #004165;

$blue-500: #7499ac; // Deep sea Spray
$blue-600: #e2eff4; // Dropdown hover
$blue-700: #c2ddeb; // Focus shadow color
$blue-800: #004a70; // Will reverify after the guideline updated.
$blue-gradient: radial-gradient(
  ellipse 100% 0% at 50% 50%,
  rgb(0, 114, 172) 0%,
  rgb(0, 86, 130) 100%
);

$shadow: $black;

$grey-100: #f7f7f7; // Page background color
$grey-200: #eeeeee;
$grey-300: #e0e0e0; // Border color & disabled btn bg
$grey-400: #cccccc; // Disabled text color
$grey-500: #747678;
$grey-600: #494949; // Text color

$green-100: #abeab6;
$green-200: #008a02; // Positive color

$orange: #df7a00; // warning color
$red: #d90000; // error color

$link: #0072ac;
$link-hover: #006090;
