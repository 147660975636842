@import 'wl-color-utils';

@mixin rd-shared-alert-theme($color-theme, $component-theme) {
  $shared-alert--primary-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--primary-background-color,
    'primary-100'
  );
  $shared-alert--primary-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--primary-border-color,
    'primary-300'
  );

  // AlertTheme success
  $shared-alert--success-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--success-background-color,
    'success'
  );
  $shared-alert--success-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--success-border-color,
    'success'
  );

  // AlertTheme warn

  $shared-alert--warn-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--warn-background-color,
    'warn'
  );
  $shared-alert--warn-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--warn-border-color,
    'warn'
  );

  // AlertTheme error
  $shared-alert--error-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--error-background-color,
    'error'
  );
  $shared-alert--error-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--error-border-color,
    'error'
  );

  // AlertTheme transparent

  $shared-alert--transparent-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--transparent-background-color,
    'transparent'
  );
  $shared-alert--transparent-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--transparent-border-color,
    'grey-300'
  );

  // AlertTheme error

  $shared-alert--error-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--error-background-color,
    'error'
  );
  $shared-alert--error-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--error-border-color,
    'error'
  );

  // For all alert themes

  $shared-alert--link-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--link-color,
    'link-100'
  );
  $shared-alert--link-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--link-hover-color,
    'link-200'
  );
  $shared-alert--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-alert--text-color,
    'text'
  );

  .rd-shared-alert {
    color: $shared-alert--text-color;

    &.alert-success {
      .alert-container {
        background-color: $shared-alert--success-background-color;
        @if $shared-alert--success-border-color != 'transparent' {
          border: 1px solid $shared-alert--success-border-color;
        }
      }
    }

    &.alert-warn {
      .alert-container {
        background-color: $shared-alert--warn-background-color;
        @if $shared-alert--warn-border-color != 'transparent' {
          border: 1px solid $shared-alert--warn-border-color;
        }
      }
    }

    &.alert-primary {
      .alert-container {
        background-color: $shared-alert--primary-background-color;
        @if $shared-alert--primary-border-color != 'transparent' {
          border: 1px solid $shared-alert--primary-border-color;
        }
      }
    }

    &.alert-transparent {
      .alert-container {
        background-color: $shared-alert--transparent-background-color;
        @if $shared-alert--transparent-border-color != 'transparent' {
          border: 1px solid $shared-alert--transparent-border-color;
        }
      }
    }

    &.alert-error {
      .alert-container {
        background-color: $shared-alert--error-background-color;
        @if $shared-alert--error-border-color != 'transparent' {
          border: 1px solid $shared-alert--error-border-color;
        }
      }
    }

    a,
    button {
      color: $shared-alert--link-color;

      &:active,
      &:hover {
        color: $shared-alert--link-hover-color;
      }
    }
  }
}
