@import 'wl-tenant-variables-utils';

@mixin rd-input-height($tenant-variables) {
  $input-height: rd-apply-tenant-variables(
    $tenant-variables,
    input-height,
    40px
  );

  .input-height {
    height: $input-height;
  }

  // ensure that date range dropdown top margin moves alongside the date range input height

  .rd-ng-select.input-height {
    .ng-dropdown-panel {
      margin-top: calc(#{$input-height} + 5px);
    }
  }

  .date-range-input {
    .dropdown-content {
      margin-top: calc(#{$input-height} + 8px);
    }
  }
}
