@import 'wl-tenant-variables-utils';

@mixin rd-hover-tile($tenant-variables) {
  $default-hover-tile-style: (
    'box-shadow': 0px 0px 10px 0px rgba(#000, 7%),
  );
  $default-active-tile-style: (
    'box-shadow': 0px 0px 10px 0px rgba(#000, 7%),
  );
  $tile-on-hover: rd-apply-tenant-variables(
    $tenant-variables,
    hover-tile-style,
    $default-hover-tile-style
  );
  $tile-on-active: rd-apply-tenant-variables(
    $tenant-variables,
    active-tile-style,
    $default-active-tile-style
  );

  [override] {
    .hover-tile {
      &:hover {
        @each $style, $value in $tile-on-hover {
          #{$style}: $value;
        }
      }

      &:active {
        @each $style, $value in $tile-on-active {
          #{$style}: $value;
        }
      }
    }
  }
}
