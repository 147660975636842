@import 'wl-typography-utils';

@mixin rd-core-header-dropdown-link-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-core-header-dropdown-link {
    .dropdown-item {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-header-dropdown-link--dropdown-item-typeface',
        'small-text-emphasis-2-desktop'
      );

      .dropdown-link-text {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'core-header-dropdown-link--dropdown-link-typeface',
          'small-text-desktop'
        );
      }
    }
  }
}
