@import 'wl-color-utils';

@mixin rd-core-header-locale-select-mobile-theme(
  $color-theme,
  $component-theme
) {
  $core-header-locale-select--border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-locale-select--border-color,
    focus
  );
  $core-header-locale-select--option-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-header-locale-select--option-color,
    'text'
  );

  .rd-core-header-locale-select-mobile {
    .select-element {
      &:focus {
        border-color: $core-header-locale-select--border-color;
      }

      option {
        color: $core-header-locale-select--option-color;
      }
    }
  }
}
