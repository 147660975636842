@import 'wl-color-utils';

@mixin rd-shared-bottom-bar-theme($color-theme, $component-theme) {
  $shared-bottom-bar--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--background-color,
    'grey-100'
  );
  $shared-bottom-bar--border-top-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--border-top-color,
    'grey-300'
  );
  $shared-bottom-bar--button-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--button-active-color,
    'grey-200'
  );
  $shared-bottom-bar--button-focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--button-focus-color,
    'focus'
  );
  $shared-bottom-bar--indicator-on-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--indicator-on-color,
    'error'
  );
  $shared-bottom-bar--icon-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--text-color,
    'grey-500'
  );
  $shared-bottom-bar--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-bottom-bar--text-color,
    'grey-500'
  );

  .rd-shared-bottom-bar {
    color: $shared-bottom-bar--text-color;

    // Container and button should have same background-color, so the color of the vertical space
    // in the divider is consistent with the button color
    .bottom-bar-container,
    .bottom-bar-button {
      background-color: $shared-bottom-bar--background-color;
    }

    .bottom-bar-container {
      border-top-color: $shared-bottom-bar--border-top-color;
    }

    .bottom-bar-button {
      &:active {
        background-color: $shared-bottom-bar--button-active-color;
      }

      &:focus {
        outline-color: $shared-bottom-bar--button-focus-color;
      }
    }

    // Colorise icons here, instead of using iconTheme, as they might not use the same theme always
    .rd-shared-icon.default .icon-fill {
      fill: $shared-bottom-bar--icon-color;
    }

    .action-button-text {
      color: $shared-bottom-bar--text-color;
    }

    .indicator-on {
      background-color: $shared-bottom-bar--indicator-on-color;
    }

    .button-divider {
      background-color: $shared-bottom-bar--text-color;
    }
  }
}
