@import 'wl-color-utils';

@mixin rd-shared-checkout-error-dialog-theme($color-theme, $component-theme) {
  $shared-checkout-error-dialog--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-checkout-error-dialog--text-color,
    'text'
  );
  $shared-checkout-error-dialog--title-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-checkout-error-dialog--title-color,
    'text'
  );

  .rd-shared-checkout-error-dialog {
    .title {
      color: $shared-checkout-error-dialog--title-color;
    }

    .description {
      color: $shared-checkout-error-dialog--text-color;
    }
  }
}
