@import 'wl-tenant-variables-utils';

@mixin rd-mat-select($tenant-variables) {
  $default-mat-select-style: (
    'border-rounded-width': 0,
  );

  $mat-select-style: rd-apply-tenant-variables(
    $tenant-variables,
    'mat-select',
    $default-mat-select-style
  );

  .mat-mdc-select {
    @if map-get($mat-select-style, 'border-rounded-width') != 0 {
      border: 1px solid var(--mat-select--border-color, var(--grey-400));
      &:focus {
        border: 1px solid var(--mat-select--border-color, var(--grey-400));
      }
      .mat-mdc-select-trigger {
        padding: var(--mat-select--padding, 8px 16px);
      }
    } @else {
      border-bottom: 1px solid var(--mat-select--border-color, var(--grey-400));
    }
  }
}
