@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-panel-accordion-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-panel-accordion {
    .mat-expansion-panel-header-title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-panel-accordion--title-typeface-mobile',
        'emphasis-1-mobile'
      );

      @include screen-from-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-panel-accordion--title-typeface-desktop',
          'emphasis-1-desktop'
        );
      }
    }

    .mat-expansion-panel-content {
      letter-spacing: normal;

      .mat-expansion-panel-body {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-panel-accordion--body-typeface-mobile',
          'text-mobile'
        );

        @include screen-from-tablet {
          @include rd-typography-style(
            $base-typography,
            $tenant-typography,
            'rd-shared-panel-accordion--body-typeface-desktop',
            'text-desktop'
          );
        }
      }
    }
  }
}
