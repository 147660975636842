@import 'wl-color-utils';

@mixin rd-core-side-nav-theme($color-theme, $component-theme) {
  $core-side-nav--element-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--element-background-color,
    primary-300
  );
  $core-side-nav--element-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--element-color,
    'background'
  );
  $core-side-nav--item-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--item-border-color,
    'background'
  );
  $core-side-nav--overlay-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--overlay-background-color,
    'shadow'
  );
  $core-side-nav--points-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--points-background-color,
    primary-100
  );
  $core-side-nav--title-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--title-background-color,
    primary-100
  );
  $core-side-nav--title-border-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--title-border-background-color,
    'background'
  );
  $core-side-nav--user-balance-container-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav--user-balance-container-color,
    'background'
  );

  .rd-core-side-nav {
    .side-nav-overlay {
      background: $core-side-nav--overlay-background-color;
    }

    .side-nav-element {
      background: $core-side-nav--element-background-color;
      color: $core-side-nav--element-color;
    }

    .user-balance-container {
      color: $core-side-nav--user-balance-container-color;

      .title-loading {
        background-color: $core-side-nav--title-background-color;
      }

      .points-loading {
        background-color: $core-side-nav--points-background-color;
      }
    }

    .item-border {
      background-color: rgba($core-side-nav--item-border-color, 0.1);

      &.title-border {
        background-color: $core-side-nav--title-border-background-color;
      }
    }
  }
}
