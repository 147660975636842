@import 'wl-color-utils';

@mixin rd-shared-error-sign-theme($color-theme, $component-theme) {
  $shared-carousel--error-sign--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--error-sign--background-color,
    error
  );
  $shared-error-sign--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-error-sign--text-color,
    grey-100
  );

  .rd-shared-error-sign {
    .error-sign {
      background-color: $shared-carousel--error-sign--background-color;
      color: $shared-error-sign--text-color;
    }
  }
}
