@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-core-auth-user-idle-dialog-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-core-auth-user-idle-dialog {
    .remaining-seconds,
    .help-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-auth-user-idle-dialog--message-typeface-desktop',
        'text-emphasis-2-desktop'
      );
    }

    .seconds-emphasis {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-auth-user-idle-dialog--seconds-text-typeface-desktop',
        'text-emphasis-1-desktop'
      );
    }
  }
}
