@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-pagination-typography($base-typography, $tenant-typography) {
  .rd-shared-pagination {
    .page {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-pagination--container-content-page-typeface',
        'caption-desktop'
      );
    }

    .page-active {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-pagination--container-content-page-active-typeface',
        'caption-desktop'
      );
    }
  }
}
