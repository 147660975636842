@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-counter-typography($base-typography, $tenant-typography) {
  .rd-shared-counter {
    input.mat-mdc-input-element {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-counter--mat-input-typeface-mobile',
        'emphasis-1-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-counter--mat-input-typeface-desktop',
          'emphasis-5-desktop'
        );
      }

      line-height: 1;
    }

    .mat-mdc-form-field-hint,
    .mat-mdc-form-field-error {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-counter--mat-hint-typeface',
        'small-text-desktop'
      );
    }
  }
}
