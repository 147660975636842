@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-fulfillment-processing-loader-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-shared-fulfillment-processing-loader {
    .processing-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-fulfillment-processing-loader--processing-text-typeface-mobile',
        'emphasis-3-desktop'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-fulfillment-processing-loader--processing-text-typeface-desktop',
          'emphasis-3-desktop'
        );
      }
    }

    .wait-disclaimer-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-fulfillment-processing-loader--wait-disclaimer-text-typeface-mobile',
        'emphasis-9-desktop'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-fulfillment-processing-loader--wait-disclaimer-text-typeface-desktop',
          'emphasis-9-desktop'
        );
      }
    }
  }
}
