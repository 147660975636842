@import 'wl-color-utils';

@mixin rd-core-auth-user-idle-dialog-theme($color-theme, $component-theme) {
  $core-auth-user-idle-dialog--message-font-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-auth-user-idle-dialog--message-font-color,
    'text'
  );

  .rd-core-auth-user-idle-dialog {
    .remaining-seconds,
    .seconds-emphasis,
    .help-text {
      color: $core-auth-user-idle-dialog--message-font-color;
    }
  }
}
