@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-core-main-footer-typography($base-typography, $tenant-typography) {
  .rd-core-main-footer {
    .copyright-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-main-footer--copy-right-typeface-desktop',
        'text-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'core-main-footer--copy-right-typeface-mobile',
          'caption-mobile'
        );
      }
    }
  }
}
