@import 'spacing-scale';

.rd-core-main-container {
  .mat-form-field-appearance-outline .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 1px;
    }
  }

  // Set to 14px as per accessibility requirements

  // Set gaps between material input fields to be 32px. Padding-bottom is 28, margin-top is 4 (so mat-label has a space to get to).

  .mat-mdc-form-field {
    top: unset;
    margin: $spacing-scale-1 0 0;

    .mat-mdc-text-field-wrapper {
      padding: 0 12px;
      height: 47px;

      .mat-mdc-floating-label {
        &:not(.mdc-floating-label--float-above) {
          left: 7px;
          top: 22px;
        }

        &.mdc-floating-label--float-above {
          max-width: max-content;
          left: 6px;
        }
      }
    }

    .mat-mdc-form-field-infix {
      min-height: 47px;
      padding: 14px 0 6px 0;
    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        flex-shrink: 0;
        width: 7px;
      }

      .mdc-notched-outline__trailing {
        width: calc(100% - 7px);
      }
    }

    // Unset subscript top property to compensate for the 32px gap.
    .mat-mdc-form-field-subscript-wrapper {
      top: unset;
      margin: 4px 0 2px 0;

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0 9px;
      }

      .mat-mdc-form-field-hint-spacer {
        display: none;
      }
    }

    &:not(.mat-form-field-no-animations) .mdc-floating-label {
      transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  // Because we increased the font-size of mat-label, the outline is now blocked by the text.
  // As a workaround, mat-label will have a background with some padding on the side so the outline is not covered by the label text.

  mat-label {
    background-color: var(
      --mat-input--main-container-label-background-color,
      var(--white)
    );
  }

  // Should remove white background-color when form is disabled. Putting CSS rule here so rules belonging to background-color are all at the same place.

  [class*='mat-form-field-appearance-'].mat-form-field-disabled {
    .mat-mdc-floating-label > mat-label {
      background-color: transparent;
    }
  }

  // Label color when form is filled, not focused, and is valid

  .mdc-text-field:not(.mdc-text-field--disabled) {
    .mat-mdc-floating-label.mdc-floating-label--float-above,
    .mat-mdc-input-element {
      color: var(--mat-input--text-color, var(--text));
    }
  }

  // Empty

  // Outline color when not yet selected

  [class*='mat-form-field-appearance-'] {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--mat-input--border-color, var(--grey-300));
    }
  }

  // Hover
  [class*='mat-form-field-appearance-']
    .mdc-text-field:hover:not(.mdc-text-field--focused)
    .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--mat-input--border-color, var(--grey-300));
    }
  }

  // Text color inside the box when empty

  .mat-form-field-hide-placeholder {
    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      color: var(--mat-input--empty-text-color, var(--grey-400));
    }
  }

  // Focused/typing

  // Outline color of the box when typing

  [class*='mat-form-field-appearance-'].mat-focused {
    .mdc-notched-outline {
      color: var(--mat-input--focused-border-color, var(--primary-200));
    }
  }

  // Text color of the label when typing

  [class*='mat-form-field-appearance-'].mat-focused {
    .mat-mdc-floating-label > mat-label {
      color: var(--mat-input--focused-label-color, var(--primary-200));
    }
  }

  // cursor color of input
  [class*='mat-form-field-appearance-'].mat-focused {
    input.mat-mdc-input-element {
      caret-color: var(--mat-input--focused-label-color, var(--primary-200));
    }
  }

  // Error

  // Border color and label color when errored

  [class*='mat-form-field-appearance-'].mat-form-field-invalid {
    .mdc-notched-outline {
      color: var(--mat-input--invalid-border-color, var(--error));

      div[class*='mdc-notched-outline__'] {
        border-color: var(
          --mat-input--invalid-border-color,
          var(--error)
        ) !important;
      }
    }

    .mat-mdc-floating-label.mdc-floating-label--float-above > mat-label {
      color: var(--mat-input--invalid-border-color, var(--error));
    }

    .mat-mdc-form-field-error {
      color: var(--mat-input--invalid-border-color, var(--error));
    }
  }

  // Disabled

  // Text color of both label and text inside the box when disabled

  /* stylelint-disable-next-line no-duplicate-selectors */
  [class*='mat-form-field-appearance-'].mat-form-field-disabled {
    color: var(--mat-input--disabled-label-color, var(--grey-500));

    &:hover .mat-mdc-floating-label > mat-label,
    .mat-mdc-floating-label > mat-label {
      color: var(--mat-input--disabled-label-color, var(--grey-500));
    }

    // Background color of text box when disabled

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--mat-input--disabled-border-color, var(--grey-300));
      background-color: var(
        --mat-input--disabled-background-color,
        var(--grey-300)
      );
    }

    // Background color of input when disabled
    .mat-mdc-form-field-infix {
      z-index: 100;

      .mat-mdc-input-element {
        background-color: transparent;
      }
    }
  }

  .mat-mdc-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-mdc-form-field-infix::after {
    color: var(--mat-input--disabled-text-color, var(--grey-500));
  }

  .mat-mdc-form-field-hint {
    color: var(--mat-hint--text-color, var(--grey-500));
  }
}
