@import 'wl-color-utils';

@mixin rd-core-main-footer-theme($color-theme, $component-theme) {
  $core-footer--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-footer--background-color,
    primary-400
  );
  $core-footer--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-footer--text-color,
    'background'
  );

  .rd-core-main-footer {
    .footer {
      background-color: $core-footer--background-color;
    }

    .copyright-text {
      color: $core-footer--text-color;
    }
  }
}
