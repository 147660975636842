:root {
  $radio-dimension: 16px;
  $ripple-dimension: 32px;

  .mat-mdc-radio-button .mdc-radio {
    width: $radio-dimension;
    height: $radio-dimension;
    padding: 0px;

    .mdc-radio__background,
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      display: block;
      width: $radio-dimension;
      height: $radio-dimension;
    }

    .mdc-radio__outer-circle {
      border-width: 1px;
    }

    .mdc-radio__inner-circle {
      top: -2px;
      left: -2px;
    }

    .mdc-radio__native-control,
    .mdc-radio__background::before,
    .mat-radio-ripple {
      width: $ripple-dimension;
      height: $ripple-dimension;
      top: calc(($ripple-dimension - $radio-dimension) / -2);
      left: calc(($ripple-dimension - $radio-dimension) / -2);
    }

    .mat-mdc-radio-touch-target {
      width: $ripple-dimension;
      height: $ripple-dimension;
    }

    .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background {
      .mdc-radio__outer-circle {
        opacity: 0.54;
      }
    }
  }

  .mat-mdc-radio-button label {
    padding-left: 8px;
    white-space: nowrap;
    font: var(--mat-select--label-typeface-mobile, var(--text-mobile));

    @screen md {
      font: var(--mat-select--label-typeface-desktop, var(--text-desktop));
    }
  }
}

mat-radio-button.mat-mdc-radio-button .mdc-form-field {
  color: inherit;
  letter-spacing: normal;
}

mat-radio-button.mat-mdc-radio-button .mdc-radio {
  // TODO: adding default (mat-radio-button--checked-color, 'primary-300') for all WLs
  // Radio
  --mdc-radio-selected-icon-color: var(--mat-radio-button--checked-color);
  --mdc-radio-selected-focus-icon-color: var(--mat-radio-button--checked-color);
  --mdc-radio-selected-hover-icon-color: var(--mat-radio-button--checked-color);
  --mdc-radio-selected-pressed-icon-color: var(
    --mat-radio-button--checked-color
  );
  --mat-mdc-radio-checked-ripple-color: var(--mat-radio-button--checked-color);
}
