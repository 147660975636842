@import 'wl-color-utils';

@mixin widgets-checkout-wizard-theme($color-theme, $component-theme) {
  $widgets-checkout-wizard--list-item-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-color,
    grey-300
  );
  $widgets-checkout-wizard--list-item-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-active-color,
    success
  );
  $widgets-checkout-wizard--list-item-number-active-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-number-active-border-color,
    success
  );
  $widgets-checkout-wizard--list-item-number-active-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-number-active-bg-color,
    success
  );
  $widgets-checkout-wizard--list-item-active-number-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-active-number-color,
    'background'
  );
  $widgets-checkout-wizard--list-item-number-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-number-border-color,
    grey-300
  );
  $widgets-checkout-wizard--list-item-number-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--list-item-number-bg-color,
    'transparent'
  );
  $widgets-checkout-wizard--progress-line-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--progress-line-bg-color,
    grey-300
  );
  $widgets-checkout-wizard--progress-line-active-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    widgets-checkout-wizard--progress-line-active-bg-color,
    grey-300
  );

  .rd-shared-checkout-wizard {
    .checkout-wizard-list-item {
      color: $widgets-checkout-wizard--list-item-color;

      &.active {
        color: $widgets-checkout-wizard--list-item-active-color;

        .checkout-wizard-list-item-number {
          background-color: $widgets-checkout-wizard--list-item-number-active-bg-color;
          border-color: $widgets-checkout-wizard--list-item-number-active-border-color;
          color: $widgets-checkout-wizard--list-item-active-number-color;
        }
      }
    }

    .checkout-wizard-list-item-number {
      background-color: $widgets-checkout-wizard--list-item-number-bg-color;
      border-color: $widgets-checkout-wizard--list-item-number-border-color;
    }

    .checkout-wizard-progress-line {
      background-color: $widgets-checkout-wizard--progress-line-bg-color;

      &.active {
        background-color: $widgets-checkout-wizard--progress-line-active-bg-color;
      }
    }
  }
}
