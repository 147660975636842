@import 'wl-color-utils';

@mixin rd-core-side-nav-link-theme($color-theme, $component-theme) {
  $core-side-nav-link--focus-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--focus-background-color,
    primary-200
  );
  $core-side-nav-link--active-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--active-background-color,
    primary-100
  );
  $core-side-nav-link--element-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--element-background-color,
    primary-300
  );
  $core-side-nav-link--item-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--item-color,
    'background'
  );
  $core-side-nav-link--link-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--link-color,
    'background'
  );
  $core-side-nav-link--logout-focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-side-nav-link--logout-focus-color,
    focus
  );

  .rd-core-side-nav-link {
    .dropdown-item {
      background: $core-side-nav-link--element-background-color;
      color: $core-side-nav-link--item-color;

      &:hover,
      &:focus {
        background-color: $core-side-nav-link--focus-background-color;
      }

      &:active {
        background-color: rgba(
          $core-side-nav-link--active-background-color,
          0.5
        );
      }

      .nav-link {
        color: $core-side-nav-link--link-color;
      }
    }

    .logout {
      &:focus {
        outline: $core-side-nav-link--logout-focus-color;
      }
    }
  }
}
