// When the width of the screen is less than $widescreen, this is the padding between the viewport edge and content
$max-screen-padding: 16px;

// Sychronise empty state icon widths
$empty-state-icon-width-desktop: 140px;
$empty-state-icon-width-mobile: 90px;

$shared-spinner-height: 60px;

// Synchronise forms across GC, TC, and RC so that on desktop, they are same width
$max-form-input-width-desktop: 415px;

$transition-animation: cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
$transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
$transition-duration: 0.4s;
