@import 'rd-theme';
@import 'anz.module.theme';
@import 'map-utils';
@import './anz-colors';

$color-theme: (
  primary-100: $blue-100,
  primary-200: $blue-200,
  primary-300: $blue-300,
  primary-400: $blue-400,
  secondary-100: $blue-100,
  secondary-200: $blue-200,
  secondary-300: $blue-300,
  secondary-400: $blue-400,
  grey-100: $grey-100,
  grey-200: $grey-200,
  grey-300: $grey-300,
  grey-400: $grey-400,
  grey-500: $grey-500,
  grey-600: $grey-600,
  // this is because the shadow color isn't finalized.
  // revert once finalized and use correct mapping for blue-700.
  grey-700: $blue-700,
  grey-800: $grey-600,
  grey-900: $grey-600,
  error: $red,
  background: $white,
  'disabled': $grey-300,
  text: $grey-600,
  warn: $orange,
  focus: $link,
  success: $green-200,
  progress: $green-100,
  shadow: $black,
  tooltip: $blue-100,
  link-100: $link,
  link-200: $link-hover,
  'transparent': transparent,
  'white': $white,
);

$core-module-theme: (
  'core-auth-session-expired-dialog--error-message-font-color':
    map-get($color-theme, 'primary-400'),
);

$shared-module-theme: (
  'shared-panel-accordion--border-color': map-get($color-theme, 'grey-300'),
  'shared-panel-accordion--header-color': map-get($color-theme, 'link-100'),
  'shared-alert--success-background-color': #e5f3e5,
  'shared-alert--success-border-color': #008a02,
  'shared-alert--primary-border-color': #add2e4,
  // Not in style guide
  'shared-alert--warn-background-color': #fbf1e5,
  // Not in style guide
  'shared-alert--error-background-color': #fcebeb,
  // Not in style guide
  'shared-bottom-bar--text-color': map-get($color-theme, 'primary-300'),
  'shared-breadcrumb--stacked-loading-color': map-get($color-theme, 'disabled'),
  'shared-breadcrumb--stacked-text-active-color':
    map-get($color-theme, 'link-100'),
  'shared-breadcrumb--stacked-text-color': map-get($color-theme, 'link-100'),
  'shared-breadcrumb--stacked-text-hover-color':
    map-get($color-theme, 'link-100'),
  'shared-button--primary-background-color': map-get($color-theme, 'link-100'),
  'shared-button--primary-background-hover-color':
    map-get($color-theme, 'link-200'),
  'shared-cash-point-slider--bg-color': map-get($color-theme, 'primary-100'),
  'shared-cash-point-slider--thumb-color': map-get($color-theme, 'primary-300'),
  'shared-cash-point-slider--thumb-ring-color':
    map-get($color-theme, 'primary-100'),
  'shared-cash-point-slider--track-bg-color': #cedde4,
  'shared-cash-point-slider--track-filled-bg-color': #cedde4,
  'shared-checkout-error-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'shared-fulfillment-processing-loader--border-color':
    map-get($color-theme, 'grey-300'),
  'shared-fulfillment-processing-loader--processing-text-color':
    map-get($color-theme, 'primary-400'),
  'shared-icon--error-stroke-color': map-get($color-theme, 'primary-400'),
  'shared-icon--success-stroke-color': map-get($color-theme, 'primary-400'),
  'shared-icon--warn-stroke-color': map-get($color-theme, 'primary-400'),
  'shared-pagination--active-page-background-color':
    map-get($color-theme, 'link-100'),
  'shared-pagination--inactive-page-text-color':
    map-get($color-theme, 'link-100'),
);

$gift-card-module-theme: (
  'gc-checkout-amount-form--amount-form-title-text-color':
    map-get($color-theme, 'primary-400'),
  'gc-checkout-amount-form--loading-item-color':
    map-get($color-theme, 'grey-300'),
  'gc-checkout-amount-form--points-currency-background-color':
    map-get($color-theme, 'primary-100'),
  'gc-checkout-form--gc-checkout-terms-and-conditions-container-border-color':
    map-get($color-theme, 'link-100'),
  'gc-checkout-information--gift-card-name-color':
    map-get($color-theme, 'primary-400'),
  'gc-checkout-physical-address-form--recipient-details-title-text-color':
    map-get($color-theme, 'primary-400'),
  'gc-checkout-recipient-detail-form--recipient-details-title-text-color':
    map-get($color-theme, 'primary-400'),
  'gc-checkout-terms-and-conditions--terms-and-condition-text-color':
    map-get($color-theme, 'link-100'),
  'gc-gift-cards-list--title-color': map-get($color-theme, 'primary-400'),
  'gc-review-body--container-bg-color': map-get($color-theme, 'white'),
  'gc-review-body--container-border-color': map-get($color-theme, 'grey-300'),
  'gc-review-body--title-font-color': map-get($color-theme, 'primary-400'),
  'gc-review-body--total-charges-bg-color':
    map-get($color-theme, 'secondary-100'),
  'gc-review-form--terms-link-text-hover-color':
    map-get($color-theme, 'link-200'),
  'gc-success-detail--background-color': map-get($color-theme, 'white'),
  'gc-success-detail--border-color': map-get($color-theme, 'grey-300'),
  'gc-success-points-transaction-status--border-color': transparent,
  'gc-success-points-transaction-status--order-reference-text-color':
    map-get($color-theme, 'text'),
  'gc-success-points-transaction-status--status-text-text-color':
    map-get($color-theme, 'primary-400'),
  'gc-terms-and-condition-dialog--title-text-color':
    map-get($color-theme, 'primary-400'),
);

$points-transaction-module-theme: (
  'pt-category-filter--category-name-color': map-get($color-theme, 'link-100'),
  'pt-category-filter--selected-category-name-color':
    map-get($color-theme, 'primary-400'),
  'pt-category-filter--selected-display-color': map-get($color-theme, 'text'),
  'pt-date-picker--button-default-text-color': map-get($color-theme, 'text'),
  'pt-date-picker--button-inrange-background-color':
    map-get($color-theme, 'grey-200'),
  'pt-date-picker--day-abbrs-text-color': map-get($color-theme, 'text'),
  'pt-date-range-input--dropdown-content-border-color':
    map-get($color-theme, 'link-200'),
  'pt-date-range-input--dropdown-content-border-right-color':
    map-get($color-theme, 'link-200'),
  'pt-date-range-input--input-section-background-color':
    map-get($color-theme, 'white'),
  'pt-date-range-input--input-section-date-input-box-shadow-color':
    map-get($color-theme, 'grey-500'),
  'pt-date-range-input--input-section-input-label-text-color':
    map-get($color-theme, 'text'),
  'pt-date-range-input--input-disabled-background-color':
    map-get($color-theme, 'grey-300'),
  'pt-date-range-input--input-disabled-text-color':
    map-get($color-theme, 'text'),
  'pt-date-range-input--input-text-color': map-get($color-theme, 'text'),
  'pt-date-range-input--main-date-input-label-text-color':
    map-get($color-theme, 'text'),
  'pt-date-range-input--main-input-border-color':
    map-get($color-theme, 'grey-500'),
  'pt-date-range-input--main-input-text-color': map-get($color-theme, 'text'),
  'pt-history-list-desktop--empty-points-transaction-title-color':
    map-get($color-theme, 'primary-400'),
  'pt-history-list-mobile--empty-points-transaction-title-color':
    map-get($color-theme, 'primary-400'),
  'pt-history-list-desktop--header-background-color':
    map-get($color-theme, 'primary-300'),
  'pt-history-list-desktop--header-text-color': map-get($color-theme, 'white'),
  'pt-history-list-desktop--history-list-item-alternate-background-color':
    map-get($color-theme, 'white'),
  'pt-history-list-desktop--history-list-item-border-color':
    map-get($color-theme, 'grey-300'),
  'pt-history-list-mobile--history-list-item-wrapper-border-color':
    map-get($color-theme, 'grey-300'),
  'pt-history-list-item--mobile-date-border-color':
    map-get($color-theme, 'grey-300'),
  'pt-points-transaction--history-list-border-color':
    map-get($color-theme, 'grey-300'),
  'pt-page-size-selector--button-text-color': map-get($color-theme, 'link-100'),
  'pt-page-size-selector--button-selected-text-color':
    map-get($color-theme, 'primary-400'),
  'pt-page-size-selector--button-active-text-color':
    map-get($color-theme, 'primary-400'),
  'pt-tile-title--text-color': map-get($color-theme, 'primary-400'),
);

$redeem-cash-module-theme: (
  'rcv2-checkout-banner--text-color': map-get($color-theme, 'primary-400'),
  'rcv2-checkout-form--title-color': map-get($color-theme, 'primary-400'),
  'rcv2-checkout-information--title-color': map-get($color-theme, 'primary-400'),
  'rcv2-checkout-linked-account--linked-account-container-background-color':
    map-get($color-theme, 'secondary-100'),
  'rcv2-checkout-linked-account--linked-account-container-border-color':
    map-get($color-theme, 'grey-700'),
  'rcv2-checkout-terms-list--redeem-cash-terms-information-container-border-color':
    map-get($color-theme, 'grey-300'),
  'rcv2-checkout-terms-list--redeem-cash-terms-list-container-border-color':
    transparent,
  'rcv2-checkout-transfer-amount-form--cash-value-bg-color':
    map-get($color-theme, 'grey-100'),
  'rcv2-checkout-transfer-amount-form--plus-minus-icon-fill-color':
    map-get($color-theme, 'white'),
  'rcv2-checkout-transfer-amount-form--plus-minus-icon-disabled-bg-color':
    map-get($color-theme, 'grey-300'),
  'rcv2-checkout-transfer-amount-form--plus-minus-icon-disabled-fill-color':
    map-get($color-theme, 'text'),
  'rcv2-membership-failed-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'rcv2-membership-success-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'rcv2-review-body--host-border-color': map-get($color-theme, 'grey-300'),
  'rcv2-review-body--title-color': map-get($color-theme, 'primary-400'),
  'rcv2-review-breakdown--computation-list-item-final-color':
    map-get($color-theme, 'text'),
  'rcv2-success-detail--background-color': map-get($color-theme, 'white'),
  'rcv2-success-detail--border-color': map-get($color-theme, 'grey-300'),
  'rcv2-success-points-transaction-status--status-text-text-color':
    map-get($color-theme, 'primary-400'),
);

$transfer-connect-module-theme: (
  'tcv2-checkout-form--header-button-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-checkout-link-account-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-checkout-linked-account--linked-account-container-background-color':
    map-get($color-theme, 'primary-100'),
  'tcv2-checkout-linked-account--linked-account-container-horizontal-border-color':
    map-get($color-theme, 'transparent'),
  'tcv2-checkout-linked-account--linked-account-container-vertical-border-color':
    map-get($color-theme, 'grey-700'),
  'tcv2-checkout-program-description--loading-color':
    map-get($color-theme, 'grey-200'),
  'tcv2-checkout-program-description--program-name-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-checkout-terms-list--loading-color': map-get($color-theme, 'grey-200'),
  'tcv2-checkout-transfer-amount-form--mat-form-field-underline-color':
    map-get($color-theme, 'grey-400'),
  'tcv2-checkout-transfer-amount-form--plus-minus-icon-fill-color':
    map-get($color-theme, 'white'),
  'tcv2-checkout-transfer-amount-form--plus-minus-icon-disabled-bg-color':
    map-get($color-theme, 'grey-300'),
  'tcv2-checkout-transfer-amount-form--plus-minus-icon-disabled-fill-color':
    map-get($color-theme, 'text'),
  'tcv2-checkout-transfer-amount-form--target-currency-label-color':
    map-get($color-theme, 'text'),
  'tcv2-checkout-transfer-amount-form--target-input-bg-color':
    map-get($color-theme, 'grey-100'),
  'tcv2-checkout-transfer-amount-form--transfer-icon-color':
    map-get($color-theme, 'grey-500'),
  'tcv2-membership-fail-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-membership-success-dialog--title-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-review-body--container-border-color': map-get($color-theme, 'grey-300'),
  'tcv2-review-body--title-font-color': map-get($color-theme, 'primary-400'),
  'tcv2-review-breakdown--computation-list-item-final-color':
    map-get($color-theme, 'text'),
  'tcv2-success-detail--border-color': map-get($color-theme, 'grey-300'),
  'tcv2-success-points-transaction-status--order-reference-text-color':
    map-get($color-theme, 'text'),
  'tcv2-success-points-transaction-status--status-text-text-color':
    map-get($color-theme, 'primary-400'),
  'tcv2-success-points-transaction-status--transfer-status-text-color':
    map-get($color-theme, 'primary-400'),
);

$auto-redemption-view-module-theme: (
  'auto-redemption-date-picker--button-default-text-color':
    map-get($color-theme, 'text'),
  'auto-redemption-date-picker--button-inrange-background-color':
    map-get($color-theme, 'grey-200'),
  'auto-redemption-date-picker--day-abbrs-text-color':
    map-get($color-theme, 'text'),
  'auto-redemption-date-input--date-picker-section-border-color':
    map-get($color-theme, 'grey-600'),
  'auto-redemption-date-input--dropdown-content-border-right-color':
    map-get($color-theme, 'link-200'),
  'auto-redemption-date-input--input-section-date-input-box-shadow-color':
    map-get($color-theme, 'grey-500'),
  'auto-redemption-date-input--input-section-input-label-text-color':
    map-get($color-theme, 'text'),
  'auto-redemption-date-input--input-text-color': map-get($color-theme, 'text'),
);

$popup-module-theme: (
  'popup-dialog--overlay-background-color':
    rgba(map-get($color-theme, shadow), 70%),
  'rd-popup-default-template--title-color': map-get($color-theme, 'primary-400'),
  'popup-sort-dialog--text-color': map-get($color-theme, 'primary-300'),
  'popup-sort-dialog-selected--text-color': map-get($color-theme, 'primary-400'),
);

$third-party-lib-module-theme: (
  'mat-input--border-color': map-get($color-theme, 'text'),
  'mat-input--disabled-border-color': map-get($color-theme, 'text'),
  'mat-input--disabled-label-color': map-get($color-theme, 'text'),
  'mat-input--disabled-text-color': map-get($color-theme, 'text'),
  'mat-input--focused-border-color': map-get($color-theme, 'primary-300'),
  'mat-input--focused-label-color': map-get($color-theme, 'primary-300'),
  'mat-radio-button--checked-color': map-get($color-theme, 'primary-400'),
  'mat-select--active-option-text-color': map-get($color-theme, 'primary-400'),
  'mat-select--background-color': map-get($color-theme, 'primary-100'),
  'mat-select--border-color': map-get($color-theme, 'grey-600'),
  'mat-select--opened-border-color': map-get($color-theme, 'link-200'),
  'mat-select--option-value-color': map-get($color-theme, 'link-100'),
  'mat-select--option-text-color': map-get($color-theme, 'link-100'),
  'mat-sidenav--background-color': map-get($color-theme, 'background'),
  'mat-checkbox--checked-color': map-get($color-theme, 'primary-400'),
  'mat-slide-toggle--toggle-bar-background-enabled':
    map-get($color-theme, 'link-100'),
  'mat-slide-toggle--toggle-bar-border-color-disabled':
    map-get($color-theme, 'link-100'),
  'mat-slide-toggle--toggle-bar-border-color-enabled':
    map-get($color-theme, 'link-100'),
  'mat-slide-toggle--toggle-thumb-background-disabled':
    map-get($color-theme, 'grey-500'),
  'mat-slide-toggle--toggle-thumb-background-enabled':
    map-get($color-theme, 'white'),
  'ng-select--border-color': map-get($color-theme, 'grey-500'),
  'ng-select--opened-border-color': map-get($color-theme, 'grey-500'),
  'ng-select--dropdown-border-color': map-get($color-theme, 'link-200'),
);

$static-pages-module-theme: (
  'rd-static-pages-contact-us--title-text-color':
    map-get($color-theme, 'primary-400'),
  'rd-static-pages-contact-us--subtitle-text-color':
    map-get($color-theme, 'grey-600'),
  'rd-static-pages-contact-us-form--input-field-text-color':
    map-get($color-theme, 'grey-600'),
);

$payment-module-theme: (
  'payment-card-detail-form--icon-color': map-get($color-theme, primary-400),
  'payment-card-detail-form--stripe-input-normal':
    map-get($color-theme, grey-500),
  'payment-card-detail-form--stripe-input-placeholder-color':
    map-get($color-theme, grey-500),
  'payment-card-detail-form--stripe-input-primary':
    map-get($color-theme, primary-400),
  'payment-form--saved-cards-border-mobile-color':
    map-get($color-theme, primary-200),
  'payment-form--saved-cards-radio-button-checked-border-desktop-color':
    map-get($color-theme, primary-400),
  'payment-form--saved-cards-radio-button-checked-border-mobile-color': #eef2f6,
  'payment-form--tab-radio-header-background-color':
    map-get($color-theme, 'white'),
  'payment-form--tab-radio-header-border-bottom-color':
    map-get($color-theme, primary-200),
  'payment-form--tab-radio-header-checked-color':
    map-get($color-theme, primary-400),
);

$widgets-theme: (
  'widgets-checkout-wizard--list-item-active-color':
    map-get($color-theme, 'primary-400'),
  'widgets-checkout-wizard--list-item-active-number-color':
    map-get($color-theme, 'background'),
  'widgets-checkout-wizard--list-item-color': map-get($color-theme, 'text'),
  'widgets-checkout-wizard--list-item-number-active-bg-color':
    map-get($color-theme, 'primary-400'),
  'widgets-checkout-wizard--list-item-number-active-border-color':
    map-get($color-theme, 'primary-400'),
  'widgets-checkout-wizard--list-item-number-bg-color':
    map-get($color-theme, 'grey-300'),
  'widgets-checkout-wizard--list-item-number-border-color':
    map-get($color-theme, grey-300),
  'widgets-checkout-wizard--progress-line-active-bg-color':
    map-get($color-theme, grey-300),
  'widgets-checkout-wizard--progress-line-bg-color':
    map-get($color-theme, grey-300),
);

$component-theme: map-collect(
  $core-module-theme,
  $shared-module-theme,
  $widgets-theme
);

@include rd-core-module-theme($color-theme, $component-theme);
@include rd-shared-module-theme($color-theme, $component-theme);
@include rd-anz-module-theme($color-theme, $component-theme);
@include widgets-checkout-wizard-theme($color-theme, $component-theme);

:root {
  @include generate-theme-variable-from-map($color-theme);
  @include generate-theme-variable-rgb-from-map(
    $color-theme,
    background grey-200 primary-300 shadow
  );

  // General theme variables
  --border-color: #e0e0e0;
  --disabled-text-color: #cccccc;
  --error-color: #d90000;
  --focus-color: #0072ac;
  --link-color: #0072ac;
  --link-hover-color: #006090;
  --progress-color: #abeab6;
  --success-color: #008a02;
  --text-color: #494949;
  --warning-color: #df7a00;

  // Auto-redemption Module
  @include generate-theme-variable-from-map($auto-redemption-view-module-theme);
  // Points Transaction Module
  @include generate-theme-variable-from-map($points-transaction-module-theme);

  @include generate-theme-variable-from-map($static-pages-module-theme);

  // RCv2 Module
  @include generate-theme-variable-from-map($redeem-cash-module-theme);
  @include generate-theme-variable-rgb-from-map(
    $redeem-cash-module-theme,
    rcv2-checkout-transfer-amount-form--plus-minus-icon-disabled-bg-color
  );
  // Gift Card
  @include generate-theme-variable-from-map($gift-card-module-theme);

  // Payment theme variables
  @include generate-theme-variable-from-map($payment-module-theme);

  // Popup theme variables
  @include generate-theme-variable-from-map($popup-module-theme);

  // Third party theme variables
  @include generate-theme-variable-from-map($third-party-lib-module-theme);

  // TCv2 theme variables
  @include generate-theme-variable-from-map($transfer-connect-module-theme);
}
