@import 'mixins';
@import 'variables';

mat-slide-toggle.rd-mat-slide-toggle.mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 26px;
    width: 50px;
    border-radius: 25px;
    background-color: var(
      --mat-slide-toggle--toggle-bar-background-disabled,
      var(--background)
    );
    border: 1px solid
      var(--mat-slide-toggle--toggle-bar-border-color-disabled, var(--grey-400));
  }

  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 4px;
    height: 18px;
    width: 18px;
  }

  .mat-slide-toggle-thumb {
    height: 18px;
    width: 18px;
    background-color: var(
      --mat-slide-toggle--toggle-thumb-background-disabled,
      var(--grey-400)
    );
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(22px, 0, 0) !important;
    }
    .mat-slide-toggle-bar {
      background: var(
        --mat-slide-toggle--toggle-bar-background-enabled,
        var(--primary-400)
      );
      border-color: var(
        --mat-slide-toggle--toggle-bar-border-color-enabled,
        var(--primary-400)
      );
    }

    .mat-slide-toggle-thumb {
      background: var(
        --mat-slide-toggle--toggle-thumb-background-enabled,
        var(--background)
      );
    }
  }
}
