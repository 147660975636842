@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-button-typography($base-typography, $tenant-typography) {
  .rd-shared-button {
    .rd-button {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-button--rd-button-typeface',
        'text-emphasis-2-mobile'
      );

      @include screen-from-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-shared-button--rd-button-typeface',
          'text-emphasis-2-desktop'
        );
      }
    }
  }
}
