@import 'wl-tenant-variables-utils';

@mixin rd-mat-form-field($tenant-variables) {
  $default-mat-form-field-style: (
    'mobile': (
      'width': (
        100%,
      ),
    ),
    'desktop': (
      'max-width': (
        500px,
      ),
      'width': (
        100%,
      ),
    ),
    'infix-styles': (
      'padding': (
        10px 0,
      ),
    ),
  );

  $mat-form-field: rd-apply-tenant-variables(
    $tenant-variables,
    mat-form-field,
    $default-mat-form-field-style
  );

  .main-container,
  .rd-dialog-container {
    .mat-mdc-form-field {
      @each $style, $value in map-get($mat-form-field, 'mobile') {
        #{$style}: $value;
      }

      @include screen-from-desktop {
        @each $style, $value in map-get($mat-form-field, 'desktop') {
          #{$style}: $value;
        }
      }
    }

    // Spacing inside a mat-form-field between the text and the border around it
    .mat-form-field-appearance-outline {
      .mat-mdc-form-field-infix {
        @each $style, $value in map-get($mat-form-field, 'infix-styles') {
          #{$style}: $value;
        }
      }
    }
  }
}
