@import 'mixins';
@import 'wl-color-utils';

// For each displayMode, there should be a set default/hover/active/loading colour state
@mixin define-breadcrumb-colors(
  $default-color,
  $hover-color,
  $active-color,
  $loading-color
) {
  .breadcrumb-link {
    color: $default-color;

    .rd-shared-icon .icon-fill {
      fill: $default-color;
    }

    &:hover {
      color: $hover-color;

      .breadcrumb-text {
        text-decoration: underline;
        text-decoration-color: $hover-color;
      }

      .rd-shared-icon .icon-fill {
        fill: $hover-color;
      }
    }

    &:active {
      color: $active-color;
      .breadcrumb-text {
        text-decoration-color: $active-color;
      }

      .rd-shared-icon .icon-fill {
        fill: $active-color;
      }
    }
  }

  .breadcrumb-link-loading {
    background-color: $loading-color;
  }
}

@mixin rd-shared-breadcrumb-theme($color-theme, $component-theme) {
  $shared-breadcrumb--stacked-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--stacked-text-color,
    'text'
  );
  $shared-breadcrumb--stacked-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--stacked-text-hover-color,
    'primary-100'
  );
  $shared-breadcrumb--stacked-text-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--stacked-text-active-color,
    'secondary-100'
  );
  $shared-breadcrumb--stacked-loading-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--stacked-text-active-color,
    'grey-300'
  );

  $shared-breadcrumb--embedded-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--embedded-text-color,
    'background'
  );
  $shared-breadcrumb--embedded-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--embedded-text-hover-color,
    'grey-100'
  );
  $shared-breadcrumb--embedded-text-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--embedded-text-active-color,
    'grey-200'
  );
  $shared-breadcrumb--embedded-loading-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--embedded-text-active-color,
    'background'
  );

  $shared-breadcrumb--focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-breadcrumb--focus-color,
    focus
  );

  .rd-shared-breadcrumb {
    &.stacked-mobile {
      @include screen-until-tablet {
        @include define-breadcrumb-colors(
          $shared-breadcrumb--stacked-text-color,
          $shared-breadcrumb--stacked-text-hover-color,
          $shared-breadcrumb--stacked-text-active-color,
          $shared-breadcrumb--stacked-loading-color
        );
      }
    }

    &.stacked-desktop {
      @include screen-from-tablet {
        @include define-breadcrumb-colors(
          $shared-breadcrumb--stacked-text-color,
          $shared-breadcrumb--stacked-text-hover-color,
          $shared-breadcrumb--stacked-text-active-color,
          $shared-breadcrumb--stacked-loading-color
        );
      }
    }

    &.embedded-mobile {
      @include screen-until-tablet {
        @include define-breadcrumb-colors(
          $shared-breadcrumb--embedded-text-color,
          $shared-breadcrumb--embedded-text-hover-color,
          $shared-breadcrumb--embedded-text-active-color,
          $shared-breadcrumb--embedded-loading-color
        );
      }
    }

    &.embedded-desktop {
      @include screen-from-tablet {
        @include define-breadcrumb-colors(
          $shared-breadcrumb--embedded-text-color,
          $shared-breadcrumb--embedded-text-hover-color,
          $shared-breadcrumb--embedded-text-active-color,
          $shared-breadcrumb--embedded-loading-color
        );
      }
    }
  }

  .breadcrumb-link.focus-link:focus {
    outline-color: $shared-breadcrumb--focus-color !important;
  }
}
