@import 'wl-color-utils';

@mixin rd-core-auth-session-expired-dialog-theme(
  $color-theme,
  $component-theme
) {
  $core-auth-session-expired-dialog--error-message-font-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-auth-session-expired-dialog--error-message-font-color,
    primary-100
  );
  $core-auth-session-expired-dialog--close-message-font-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    core-auth-session-expired-dialog--close-message-font-color,
    'text'
  );

  .rd-core-auth-session-expired-dialog {
    .error-message {
      color: $core-auth-session-expired-dialog--error-message-font-color;
    }

    .close-message {
      color: $core-auth-session-expired-dialog--close-message-font-color;
    }
  }
}
