@import 'wl-color-utils';

@mixin rd-shared-fulfillment-processing-loader-theme(
  $color-theme,
  $component-theme
) {
  $shared-fulfillment-processing-loader--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-fulfillment-processing-loader--background-color,
    'background'
  );
  $shared-fulfillment-processing-loader--border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-fulfillment-processing-loader--border-color,
    'transparent'
  );
  $shared-fulfillment-processing-loader--processing-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-fulfillment-processing-loader--processing-text-color,
    'primary-100'
  );

  .rd-shared-fulfillment-processing-loader {
    background-color: $shared-fulfillment-processing-loader--background-color;
    @if $shared-fulfillment-processing-loader--border-color != 'transparent' {
      border: 1px solid $shared-fulfillment-processing-loader--border-color;
    }
    .processing-text {
      color: $shared-fulfillment-processing-loader--processing-text-color;
    }
  }
}
