@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-error-sign-typography($base-typography, $tenant-typography) {
  .rd-shared-error-sign {
    .error-sign {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-shared-checkout-wizard--rd-shared-error-sign-typeface',
        'emphasis-4-desktop'
      );
    }
  }
}
