$mobile: 400px !default; // Breakpoint for small devices
$tablet: 768px !default;
$desktop: 960px !default;
$widescreen: 1024px !default;
$fullhd-enabled: false !default;
$widescreen-enabled: false !default;

@import 'variables';

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin desktop-only() {
  @if $widescreen-enabled {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
      @content;
    }
  }
}

@mixin tablet-only() {
  @if $widescreen-enabled {
    @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
      @content;
    }
  }
}

@mixin screen-max-size {
  max-width: $widescreen;
  margin-left: auto;
  margin-right: auto;
}

@mixin padding-until-widescreen($padding: $max-screen-padding) {
  padding-left: $padding;
  padding-right: $padding;

  @include from($widescreen + $max-screen-padding * 2) {
    padding-left: 0;
    padding-right: 0;
  }
}

/* applies until max-width: $device */

@mixin screen-until-widescreen {
  @include until($widescreen + 1px) {
    @content;
  }
}

@mixin screen-until-desktop {
  @include until($desktop) {
    @content;
  }
}

@mixin screen-until-tablet {
  @include until($tablet) {
    @content;
  }
}

@mixin screen-until-mobile {
  @include until($mobile) {
    @content;
  }
}

/* applies from min-width: $device */

@mixin screen-from-desktop {
  @include from($desktop) {
    @content;
  }
}

@mixin screen-from-tablet {
  @include from($tablet) {
    @content;
  }
}

@mixin screen-from-mobile {
  @include from($mobile) {
    @content;
  }
}

@mixin screen-from-widescreen {
  @include from($widescreen + 1px) {
    @content;
  }
}

/* viewport specific, min-width to max-width */

@mixin screen-desktop-only {
  @include desktop-only {
    @content;
  }
}

@mixin screen-tablet-only {
  @include tablet-only {
    @content;
  }
}

@mixin screen-mobile-only {
  @media screen and (min-width: $mobile) and (max-width: $tablet - 1px) {
    @content;
  }
}

// Computation:
// If we have 300px of space, 3 elements, and we want an element to have 90px width, with 15px between them
// 3 * width of each element + 30px should equal 300px
// So we redistribute the widths based on the width formula here, then set that every n elements, there is no margin-right
@mixin distributed-evenly(
  $elements-per-line: 2,
  $margin-between-elements: 10px
) {
  width: calc(
    ((100% / #{$elements-per-line}) - 0.1%) -
      (
        #{$margin-between-elements} * (#{$elements-per-line} - 1) / #{$elements-per-line}
      )
  );

  :host-context(.ltr) & {
    margin-right: $margin-between-elements;
  }

  :host-context(.rtl) & {
    margin-left: $margin-between-elements;
  }

  &:nth-of-type(#{$elements-per-line}n) {
    :host-context(.ltr) & {
      margin-right: 0;
    }

    :host-context(.rtl) & {
      margin-left: 0;
    }
  }

  $non-first-line-element-index: $elements-per-line + 1;

  // Ensure gutter above the 2nd-line onwards
  &:nth-of-type(n + #{$non-first-line-element-index}) {
    margin-top: $margin-between-elements;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin scrollbar-hide {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari
  }
}

@mixin flip-background {
  transform: scaleX(-1);
}
