@import 'wl-color-utils';

@mixin rd-anz-gc-checkout-form-theme($color-theme, $component-theme) {
  $anz-gc-checkout-form--setup-auto-redemp-title-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    anz-gc-checkout-form--setup-auto-redemp-title-text-color,
    primary-400
  );

  $anz-gc-checkout-form--setup-auto-redemp-button-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    anz-gc-checkout-form--setup-auto-redemp-button-text-color,
    primary-300
  );

  .anz-gc-checkout-form {
    .setup-auto-redemp-title {
      color: $anz-gc-checkout-form--setup-auto-redemp-title-text-color;
    }

    .setup-auto-redemp-button-text {
      color: $anz-gc-checkout-form--setup-auto-redemp-button-text-color;
    }
  }
}
