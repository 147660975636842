@import 'wl-color-utils';

@mixin rd-shared-icon-theme($color-theme, $component-theme) {
  $shared-icon--default-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--default-stroke-color,
    'grey-600'
  );

  $shared-icon--primary-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--primary-stroke-color,
    'primary-400'
  );

  $shared-icon--success-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--success-stroke-color,
    'success'
  );

  $shared-icon--warn-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--warn-stroke-color,
    'warn'
  );

  $shared-icon--error-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--error-stroke-color,
    'error'
  );

  $shared-icon--link-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--link-stroke-color,
    'link-100'
  );

  $shared-icon--disabled-stroke-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-icon--disabled-stroke-color,
    'disabled'
  );

  .rd-shared-icon {
    &.default {
      .icon-path {
        stroke: $shared-icon--default-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--default-stroke-color;
      }
    }

    &.primary {
      .icon-path {
        stroke: $shared-icon--primary-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--primary-stroke-color;
      }
    }

    &.success {
      .icon-path {
        stroke: $shared-icon--success-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--success-stroke-color;
      }
    }

    &.warn {
      .icon-path {
        stroke: $shared-icon--warn-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--warn-stroke-color;
      }
    }

    &.error {
      .icon-path {
        stroke: $shared-icon--error-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--error-stroke-color;
      }
    }

    &.link {
      .icon-path {
        stroke: $shared-icon--link-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--link-stroke-color;
      }
    }

    &.disabled {
      .icon-path {
        stroke: $shared-icon--disabled-stroke-color;
      }

      .icon-fill {
        fill: $shared-icon--disabled-stroke-color;
      }
    }

    &.white {
      .icon-path {
        stroke: #fff;
      }

      .icon-fill {
        fill: #fff;
      }
    }
  }
}
