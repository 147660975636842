$cdn-url: 'https://d2xr67b5foriew.cloudfront.net/fonts/myriadpro';

@font-face {
  font-family: 'MyriadPro';
  font-weight: 400;
  font-style: normal;
  src: url('#{$cdn-url}/MyriadPro-Regular.woff2') format('woff2'),
    url('#{$cdn-url}/MyriadPro-Regular.woff') format('woff'),
    url('#{$cdn-url}/MyriadPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MyriadPro';
  font-weight: 400;
  font-style: italic;
  src: url('#{$cdn-url}/MyriadPro-It.woff2') format('woff2'),
    url('#{$cdn-url}/MyriadPro-It.woff') format('woff'),
    url('#{$cdn-url}/MyriadPro-It.otf') format('opentype');
}

@font-face {
  font-family: 'MyriadPro';
  font-weight: 600;
  font-style: normal;
  src: url('#{$cdn-url}/MyriadPro-Semibold.woff2') format('woff2'),
    url('#{$cdn-url}/MyriadPro-Semibold.woff') format('woff'),
    url('#{$cdn-url}/MyriadPro-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'MyriadPro';
  font-weight: 600;
  font-style: italic;
  src: url('#{$cdn-url}/MyriadPro-SemiboldIt.woff2') format('woff2'),
    url('#{$cdn-url}/MyriadPro-SemiboldIt.woff') format('woff'),
    url('#{$cdn-url}/MyriadPro-SemiboldIt.otf') format('opentype');
}
