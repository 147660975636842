@import 'wl-color-utils';

@mixin rd-shared-section-title-theme($color-theme, $component-theme) {
  $shared-section-title--border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-section-title--border-color,
    primary-200
  );
  $shared-section-title--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-section-title--text-color,
    primary-400
  );

  .rd-shared-section-title {
    .title {
      color: $shared-section-title--text-color;

      .ltr & {
        border-left-color: $shared-section-title--border-color;
      }

      .rtl & {
        border-right-color: $shared-section-title--border-color;
      }
    }
  }
}
