@import 'mixins';
@import 'wl-tenant-variables-utils';

// top, bottom, left, right
$directional-mapping: (
  't': [ 'border-top-left-radius',
  'border-top-right-radius' ],
  'b': [ 'border-bottom-left-radius',
  'border-bottom-right-radius' ],
  'l': [ 'border-bottom-left-radius',
  'border-top-left-radius' ],
  'r': [ 'border-bottom-right-radius',
  'border-top-right-radius' ],
);

@mixin rd-border-radius($tenant-variables) {
  $border-radius-md: rd-apply-tenant-variables(
    $tenant-variables,
    border-radius-md,
    0
  );

  .rounded-md {
    border-radius: $border-radius-md;
  }

  .rounded-md-mobile {
    // covers screens smaller than desktop resolution
    @include screen-until-desktop {
      border-radius: $border-radius-md;
    }
  }

  .rounded-md-desktop {
    @include screen-from-desktop {
      border-radius: $border-radius-md;
    }
  }

  @each $direction, $class-names in $directional-mapping {
    .rounded-md-#{$direction} {
      @each $class-name in $class-names {
        #{$class-name}: $border-radius-md;
      }
    }

    .rounded-md-#{$direction}-mobile {
      @include screen-until-desktop {
        @each $class-name in $class-names {
          #{$class-name}: $border-radius-md;
        }
      }
    }

    .rounded-md-#{$direction}-desktop {
      @include screen-from-desktop {
        @each $class-name in $class-names {
          #{$class-name}: $border-radius-md;
        }
      }
    }

    /* stylelint-disable max-nesting-depth */
    @each $text-direction in ('ltr', 'rtl') {
      .#{$text-direction} {
        .#{$text-direction}\:rounded-md-#{$direction} {
          @each $class-name in $class-names {
            #{$class-name}: $border-radius-md;
          }
        }

        .#{$text-direction}\:rounded-md-#{$direction}-mobile {
          @include screen-until-desktop {
            @each $class-name in $class-names {
              #{$class-name}: $border-radius-md;
            }
          }
        }

        .#{$text-direction}\:rounded-md-#{$direction}-desktop {
          @include screen-from-desktop {
            @each $class-name in $class-names {
              #{$class-name}: $border-radius-md;
            }
          }
        }
      }
    }
    /* stylelint-enable max-nesting-depth */
  }
}

@mixin rd-arrowed-button-border-radius($tenant-variables) {
  $arrowed-button-border-radius: rd-apply-tenant-variables(
    $tenant-variables,
    arrowed-button-border-radius,
    0
  );

  .arrowed-button-border-radius {
    border-radius: $arrowed-button-border-radius;
  }
}

@mixin rd-denomination-pills-border-radius-wrapper($tenant-variables) {
  $denomination-pills-border-radius-wrapper: rd-apply-tenant-variables(
    $tenant-variables,
    denomination-pills-border-radius-wrapper,
    4px
  );

  .denomination-pills-border-radius-wrapper {
    border-radius: $denomination-pills-border-radius-wrapper;
  }
}

@mixin rd-denomination-pills-border-radius-content($tenant-variables) {
  $denomination-pills-border-radius-content: rd-apply-tenant-variables(
    $tenant-variables,
    denomination-pills-border-radius-content,
    4px
  );

  .denomination-pills-border-radius-content {
    border-radius: $denomination-pills-border-radius-content;
  }
}

@mixin rd-type-pills-border-radius-wrapper($tenant-variables) {
  $type-pills-border-radius-wrapper: rd-apply-tenant-variables(
    $tenant-variables,
    type-pills-border-radius-wrapper,
    4px
  );

  .type-pills-border-radius-wrapper {
    border-radius: $type-pills-border-radius-wrapper;
  }
}

@mixin rd-type-pills-border-radius-content($tenant-variables) {
  $type-pills-border-radius-content: rd-apply-tenant-variables(
    $tenant-variables,
    type-pills-border-radius-content,
    4px
  );

  .type-pills-border-radius-content {
    border-radius: $type-pills-border-radius-content;
  }
}
