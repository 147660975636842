@import 'wl-color-utils';

@mixin rd-shared-cash-points-slider-theme($color-theme, $component-theme) {
  $shared-cash-point-slider--bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--bg-color,
    transparent
  );

  $shared-cash-point-slider--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--text-color,
    text
  );

  $shared-cash-point-slider--thumb-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--thumb-color,
    inherit
  );

  $shared-cash-point-slider--thumb-ring-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--thumb-ring-color,
    transparent
  );

  $shared-cash-point-slider--track-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--track-bg-color,
    inherit
  );

  $shared-cash-point-slider--track-filled-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-cash-point-slider--track-filled-bg-color,
    inherit
  );

  .rd-shared-cash-points-slider {
    .cash-points-slider-container {
      color: $shared-cash-point-slider--text-color;
      background-color: $shared-cash-point-slider--bg-color;
    }

    .mat-accent .mat-slider-thumb-label-text {
      color: $shared-cash-point-slider--text-color;
    }

    @if $shared-cash-point-slider--thumb-color != 'inherit' {
      .ascenda-ngx-slider .slider-pointer::before {
        background-color: $shared-cash-point-slider--thumb-color;
      }
    }

    @if $shared-cash-point-slider--thumb-ring-color != 'transparent' {
      .ascenda-ngx-slider .slider-pointer::before {
        border-color: $shared-cash-point-slider--thumb-ring-color;
      }
    }

    @if $shared-cash-point-slider--track-bg-color != 'inherit' {
      .ascenda-ngx-slider .slider-bar {
        background-color: $shared-cash-point-slider--track-bg-color;
      }
    }

    @if $shared-cash-point-slider--track-filled-bg-color != 'inherit' {
      .ascenda-ngx-slider .selected-range {
        background-color: $shared-cash-point-slider--track-filled-bg-color;
      }
    }
  }
}
