@import 'wl-typography-utils';

@mixin rd-core-side-nav-typography($base-typography, $tenant-typography) {
  .rd-core-side-nav {
    .title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-side-nav--title-typeface',
        'text-desktop'
      );
    }

    .currency {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-side-nav--currency-typeface',
        'text-desktop'
      );
    }

    .big-number {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-side-nav--big-number-typeface',
        'emphasis-4-desktop'
      );
    }
  }
}
