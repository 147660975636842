@import 'wl-tenant-variables-utils';

@mixin rd-mat-sidenav($tenant-variables) {
  $default-mat-sidenav-style: (
    'position': (
      absolute,
    ),
  );

  $mat-sidenav: rd-apply-tenant-variables(
    $tenant-variables,
    mat-sidenav,
    $default-mat-sidenav-style
  );

  .main-container {
    .mat-drawer-backdrop {
      position: map-get($mat-sidenav, 'position');
    }
  }
}
