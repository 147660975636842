@import 'mixins';
@import 'wl-tenant-variables-utils';
// The class .rd-ng-select is intentional; some components might want to incorporate custom styling
// separate from other instances of ng-select on the app.
ng-select.rd-ng-select.ng-select {
  $border-radius-md: rd-apply-tenant-variables(
    $tenant-variables,
    border-radius-md,
    0
  );
  border-radius: $border-radius-md;
  display: flex;
  width: 100%;
  background-color: var(--ng-select--background-color, var(--background));
  color: var(--ng-select--text-color, var(--text));
  font: var(
    --ng-select--unselected-expanded-option-typeface,
    var(--text-mobile)
  );

  &.ng-select-opened {
    .ng-select-container {
      border-radius: $border-radius-md;
      border: 1px solid
        var(--ng-select--opened-border-color, var(--primary-400));
    }
  }
  .ng-select-container {
    cursor: pointer;
    border-radius: $border-radius-md;
    padding: 5px 9px;
    border: 1px solid var(--ng-select--border-color, var(--grey-100));
    font: var(
      --ng-select--unexpanded-option-typeface,
      var(--text-emphasis-2-mobile)
    );
  }
  // margin-top rule (space between ng-select and ng-select dropdown)
  // when opened depends on input-height - check tenant-variables/input-height.scss
  .ng-dropdown-panel {
    left: 0;
    border-radius: $border-radius-md;
    border: 1px solid var(--ng-select--dropdown-border-color, var(--grey-100));
    background-color: var(
      --ng-select--dropdown-background-color,
      var(--background)
    );
    box-shadow: rgba(
        var(--ng-select--dropdown-box-shadow-color-rgb, var(--shadow-rgb)),
        0.1
      )
      0 0 3px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items {
    border-radius: $border-radius-md; // Fixes overflow on focused item
    max-height: 400px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    border-top: 0;
    padding-bottom: 13px;
    padding-top: 13px;

    &.ng-option-marked {
      background-color: var(
        --ng-select--dropdown-panel-item-marked-color,
        var(--primary-100)
      );
    }
    .ltr & {
      padding-left: 9px;
    }
    .rtl & {
      padding-right: 9px;
    }
  }
  .ng-value {
    align-items: center;
    display: flex;
    line-height: 1; // Force align center
    width: 100%;
  }
  .expander-icon {
    width: 12px;
    .ltr & {
      margin-left: auto;
    }
    .rtl & {
      margin-right: auto;
    }
  }
  &.ng-select-searchable .ng-value-container input {
    font: var(
      --ng-select--unexpanded-option-typeface,
      var(--text-emphasis-2-mobile)
    );
    .ltr & {
      padding-left: 9px;
    }
    .rtl & {
      padding-right: 9px;
    }
  }
  &.ng-select-focused > .ng-select-container {
    border-color: var(--ng-select--dropdown-box-focus-color, var(--focus));
    box-shadow: 0 0 0 1px
      var(--ng-select--dropdown-box-focus-color, var(--focus));
    background-color: var(
      --ng-select--dropdown-box-focus-background-color,
      transparent
    );

    .ng-placeholder {
      background-color: var(
        --ng-select--dropdown-box-focus-background-color,
        transparent
      );
    }
  }

  // The selected option when the dropdown is unexpanded
  .ng-option-selected {
    font: var(
      --ng-select--selected-expanded-option-typeface,
      var(--text-emphasis-2-mobile)
    );
  }
}
