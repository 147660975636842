@import 'wl-color-utils';

@mixin rd-shared-panel-accordion-theme($color-theme, $component-theme) {
  $shared-panel-accordion--border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--border-color,
    transparent
  );

  $shared-panel-accordion--header-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--header-color,
    text
  );

  $shared-panel-accordion--toggle-icon-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--toggle-icon-color,
    link-100
  );

  $shared-panel-accordion--header-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--header-border-color,
    transparent
  );

  $shared-panel-accordion--header-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--header-border-color,
    transparent
  );

  $shared-panel-accordion--content-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-panel-accordion--content-color,
    text
  );

  .rd-panel-accordion {
    @if $shared-panel-accordion--border-color != transparent {
      .mat-expansion-panel-header {
        border: 1px solid $shared-panel-accordion--border-color;
      }

      .mat-expansion-panel-content {
        border: 1px solid $shared-panel-accordion--border-color;
      }
    }

    @if $shared-panel-accordion--header-background-color != transparent {
      .mat-expansion-panel-header {
        background-color: $shared-panel-accordion--header-background-color;
      }
    }

    .mat-expansion-panel-header-title {
      color: $shared-panel-accordion--header-color;
    }

    .rd-shared-icon {
      .icon-fill {
        fill: $shared-panel-accordion--toggle-icon-color;
      }
    }

    .mat-expansion-panel-body {
      color: $shared-panel-accordion--content-color;
    }
  }
}
