@import 'wl-color-utils';

@mixin rd-shared-button-theme($color-theme, $component-theme) {
  $shared-button--focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--focus-color,
    focus
  );

  $shared-button--primary-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-background-color,
    primary-300
  );
  $shared-button--primary-background-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-background-hover-color,
    primary-200
  );
  $shared-button--primary-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-text-color,
    'background'
  );
  $shared-button--primary-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-text-hover-color,
    'background'
  );
  $shared-button--primary-background-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-background-disabled-color,
    'disabled'
  );
  $shared-button--primary-border-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-border-disabled-color,
    'disabled'
  );
  $shared-button--primary-text-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-text-disabled-color,
    grey-100
  );

  $shared-button--secondary-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-background-color,
    secondary-200
  );
  $shared-button--secondary-background-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-background-hover-color,
    secondary-100
  );
  $shared-button--secondary-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-text-color,
    'background'
  );
  $shared-button--secondary-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-text-hover-color,
    'background'
  );
  $shared-button--secondary-background-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-background-disabled-color,
    'disabled'
  );
  $shared-button--secondary-border-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-border-disabled-color,
    'disabled'
  );
  $shared-button--secondary-text-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--secondary-text-disabled-color,
    grey-100
  );

  $shared-button--error-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--error-background-color,
    error
  );
  $shared-button--error-background-hover-color: lighten(
    rd-comp-theme(
      $color-theme,
      $component-theme,
      shared-button--error-background-hover-color,
      error
    ),
    10%
  );
  $shared-button--error-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--primary-text-color,
    'background'
  );
  $shared-button--error-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--error-text-hover-color,
    error
  );
  $shared-button--error-background-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--error-background-disabled-color,
    'disabled'
  );
  $shared-button--error-border-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--error-border-disabled-color,
    'disabled'
  );
  $shared-button--error-text-disabled-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--error-text-disabled-color,
    grey-100
  );

  $shared-button--outline-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-button--outline-background-color,
    'background'
  );

  $button-types: 'primary', 'secondary', 'error';

  // Mappings for button types to button colors - Sass does not allow interpolation on variable names, so we have a map for each.

  $button-background-colors: (
    'primary': $shared-button--primary-background-color,
    'secondary': $shared-button--secondary-background-color,
    'error': $shared-button--error-background-color,
  );

  $button-text-colors: (
    'primary': $shared-button--primary-text-color,
    'secondary': $shared-button--secondary-text-color,
    'error': $shared-button--error-text-color,
  );

  $button-hover-background-colors: (
    'primary': $shared-button--primary-background-hover-color,
    'secondary': $shared-button--secondary-background-hover-color,
    'error': $shared-button--error-background-hover-color,
  );

  $button-hover-text-colors: (
    'primary': $shared-button--primary-text-hover-color,
    'secondary': $shared-button--secondary-text-hover-color,
    'error': $shared-button--error-background-hover-color,
  );

  $button-disabled-background-colors: (
    'primary': $shared-button--primary-background-disabled-color,
    'secondary': $shared-button--secondary-background-disabled-color,
    'error': $shared-button--error-background-disabled-color,
  );

  $button-disabled-border-colors: (
    'primary': $shared-button--primary-border-disabled-color,
    'secondary': $shared-button--secondary-border-disabled-color,
    'error': $shared-button--error-border-disabled-color,
  );

  $button-disabled-text-colors: (
    'primary': $shared-button--primary-text-disabled-color,
    'secondary': $shared-button--secondary-text-disabled-color,
    'error': $shared-button--error-background-disabled-color,
  );

  .rd-shared-button {
    // Outlined buttons - how this works, by default:
    // Default border-color and text-color would be the button-type, background-color is white/background
    // Then when hover/active, invert it - background-color would be the button-type color, and text-color would be white
    @each $button-type in $button-types {
      &.outline-#{$button-type} {
        background-color: $shared-button--outline-background-color;
        border-color: map-get($button-background-colors, $button-type);

        &:hover:not(:disabled),
        &:active:not(:disabled) {
          background-color: map-get($button-background-colors, $button-type);
          color: map-get($button-text-colors, $button-type);
        }

        .rd-button {
          background-color: $shared-button--outline-background-color;
          color: map-get($button-background-colors, $button-type);

          /* stylelint-disable max-nesting-depth */
          &:hover:not(:disabled),
          &:active:not(:disabled) {
            background-color: map-get($button-background-colors, $button-type);
            color: map-get($button-text-colors, $button-type);
          }

          &:disabled {
            color: map-get($button-disabled-text-colors, $button-type);
          }
          /* stylelint-enable max-nesting-depth */
        }
      }
    }

    // Block buttons
    @each $button-type in $button-types {
      .rd-button {
        &.#{$button-type} {
          background-color: map-get($button-background-colors, $button-type);

          /* stylelint-disable max-nesting-depth */
          .rd-button-text {
            color: map-get($button-text-colors, $button-type);
          }

          &:hover,
          &:active {
            background-color: map-get(
              $button-hover-background-colors,
              $button-type
            );

            .rd-button-text {
              color: map-get($button-hover-text-colors, $button-type);
            }
          }

          &:disabled {
            border-color: map-get($button-disabled-border-colors, $button-type);
            background-color: map-get(
              $button-disabled-background-colors,
              $button-type
            );
            .rd-button-text {
              color: map-get($button-disabled-text-colors, $button-type);
            }
          }
          /* stylelint-enable max-nesting-depth */
        }
      }
    }

    // Transparent buttons

    $transparent-button-types: 'primary', 'secondary';

    @each $button-type in $transparent-button-types {
      &.transparent-#{$button-type} {
        background-color: transparent;
        border: 1px solid map-get($button-background-colors, $button-type);
        display: flex;
        height: 100%;
        width: 100%;

        &.is-disabled {
          border-color: map-get($button-disabled-border-colors, $button-type);
        }

        &:hover:not(:disabled),
        &:active:not(:disabled) {
          background-color: map-get($button-background-colors, $button-type);
          color: map-get($button-text-colors, $button-type);
        }

        .rd-button {
          background-color: transparent;
          color: map-get($button-background-colors, $button-type);

          /* stylelint-disable max-nesting-depth */
          &:hover:not(:disabled),
          &:active:not(:disabled) {
            background-color: map-get($button-background-colors, $button-type);
            color: map-get($button-text-colors, $button-type);
          }

          &:disabled {
            color: map-get($button-disabled-text-colors, $button-type);
          }
          /* stylelint-enable max-nesting-depth */
        }
      }
    }

    .rd-button {
      &:focus {
        outline-color: $shared-button--focus-color;
      }
    }
  }
}
