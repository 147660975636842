@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-core-auth-session-expired-dialog-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-core-auth-session-expired-dialog {
    .close-message {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-auth-session-expired-dialog--close-message-typeface-desktop',
        'text-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'core-auth-session-expired-dialog--close-message-typeface-mobile',
          'text-mobile'
        );
      }
    }

    .error-message {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'core-auth-session-expired-dialog--error-message-typeface-desktop',
        'heading-2-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'core-auth-session-expired-dialog--error-message-typeface-mobile',
          'emphasis-7-mobile'
        );
      }
    }
  }
}
