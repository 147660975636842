@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-shared-checkout-error-dialog-typography(
  $color-theme,
  $component-theme
) {
  .rd-shared-checkout-error-dialog {
    .description {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'shared-checkout-error-dialog--description-typeface-mobile',
        'text-mobile'
      );

      @include screen-from-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'shared-checkout-error-dialog--description-typeface-desktop',
          'text-desktop'
        );
      }
    }

    .title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'shared-checkout-error-dialog--title-typeface-mobile',
        'heading-3-mobile'
      );

      @include screen-from-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'shared-checkout-error-dialog--title-typeface-desktop',
          'heading-3-desktop'
        );
      }
    }
  }
}
