@import 'wl-color-utils';

@mixin rd-shared-pagination-theme($color-theme, $component-theme) {
  $shared-pagination--active-page-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-pagination--active-page-background-color,
    primary-200
  );
  $shared-pagination--active-page-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-pagination--active-page-text-color,
    'background'
  );
  $shared-pagination--inactive-page-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-pagination--inactive-page-text-color,
    grey-500
  );

  .rd-shared-pagination {
    .page {
      color: $shared-pagination--inactive-page-text-color;
    }

    .page-active {
      background-color: $shared-pagination--active-page-background-color;
      color: $shared-pagination--active-page-text-color;
    }
  }
}
