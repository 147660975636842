@import 'wl-color-utils';

@mixin rd-shared-keyword-filter-theme($color-theme, $component-theme) {
  $shared-keyword-filter--keyword-search-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-keyword-filter--keyword-search-border-color,
    grey-400
  );
  $shared-keyword-filter--keyword-search-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-keyword-filter--keyword-search-text-color,
    'text'
  );
  $shared-keyword-filter--keyword-search-placeholder-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-keyword-filter--keyword-search-placeholder-color,
    rgba($shared-keyword-filter--keyword-search-text-color, 0.3)
  );
  $shared-keyword-filter--keyword-search-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-keyword-filter--keyword-search-bg-color,
    grey-100
  );
  $shared-keyword-filter--keyword-search-focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-keyword-filter--keyword-search-focus-color,
    focus
  );

  .rd-shared-keyword-filter {
    .keyword-search {
      border-color: $shared-keyword-filter--keyword-search-border-color;
      color: $shared-keyword-filter--keyword-search-text-color;
      background-color: $shared-keyword-filter--keyword-search-bg-color;

      &:focus {
        box-shadow: 0px 0px 0px 2px
          $shared-keyword-filter--keyword-search-focus-color;
      }

      // Do not group these placeholder selectors.
      // If one of the selector in the group is invalid,
      // the browser will ignore all other selectors in the group
      &::placeholder {
        color: $shared-keyword-filter--keyword-search-placeholder-color;
      }
      &::-webkit-input-placeholder {
        color: $shared-keyword-filter--keyword-search-placeholder-color;
      }
      &:-moz-placeholder {
        color: $shared-keyword-filter--keyword-search-placeholder-color;
      }
      &::-moz-placeholder {
        color: $shared-keyword-filter--keyword-search-placeholder-color;
      }
      &:-ms-input-placeholder {
        color: $shared-keyword-filter--keyword-search-placeholder-color;
      }
    }
  }
}
