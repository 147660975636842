@import 'wl-tenant-variables-utils';

@mixin rd-focus-link($tenant-variables) {
  $default-focus-link-style: (
    'outline-style': (
      solid,
    ),
    'outline-width': (
      2px,
    ),
  );
  $focus-link-style: rd-apply-tenant-variables(
    $tenant-variables,
    focus-link-style,
    $default-focus-link-style
  );
  $apply-on-active-state: rd-apply-tenant-variables(
    $tenant-variables,
    focus-link-on-active,
    false
  );

  .cdk-keyboard-focused {
    .focus-link {
      &:focus {
        @each $style, $value in $focus-link-style {
          #{$style}: $value !important;
        }
      }
    }
  }

  .cdk-program-focused {
    h1.focus-link {
      outline: none;
    }
  }

  @if $apply-on-active-state {
    .cdk-touch-focused,
    .cdk-mouse-focused {
      .focus-link:active {
        @each $style, $value in $focus-link-style {
          #{$style}: $value !important;
        }
      }
    }
  }
}
