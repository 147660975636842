@import 'wl-color-utils';

@mixin rd-shared-carousel-theme($color-theme, $component-theme) {
  $shared-carousel--button-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--button-active-color,
    primary-200
  );
  $shared-carousel--button-active-icon-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--button-active-icon-color,
    'background'
  );
  $shared-carousel--button-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--button-background-color,
    'background'
  );
  $shared-carousel--button-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--button-border-color,
    grey-400
  );
  $shared-carousel--button-focus-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--button-focus-color,
    focus
  );
  $shared-carousel--inner-item-container-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    shared-carousel--inner-item-container-border-color,
    primary-100
  );

  .rd-shared-carousel {
    .carousel-button {
      background-color: $shared-carousel--button-background-color;
      border-color: $shared-carousel--button-border-color;

      &:active {
        background-color: $shared-carousel--button-active-color;
      }

      &:not(:disabled) {
        &:hover {
          background-color: $shared-carousel--button-active-color;
        }

        &:focus {
          background-color: $shared-carousel--button-active-color;
          box-shadow: 0 0 0 2px $shared-carousel--button-focus-color;
        }

        /* stylelint-disable max-nesting-depth */
        &:active,
        &:hover,
        &:focus {
          .previous-arrow .icon-path,
          .next-arrow .icon-path {
            stroke: $shared-carousel--button-active-icon-color;
          }
        }
        /* stylelint-enable max-nesting-depth */
      }
    }

    .carousel-inner-item-container {
      border-color: $shared-carousel--inner-item-container-border-color;
    }
  }
}
