// Tries to grab variable from component-theme first, if not, use fallback from color-theme
@function rd-comp-theme(
  $color-theme,
  $component-theme,
  $component-default,
  $color-fallback
) {
  @return if(
    map-has-key($component-theme, #{$component-default}),
    map-get($component-theme, #{$component-default}),
    map-get($color-theme, #{$color-fallback})
  );
}

@mixin generate-theme-variable-from-map($map) {
  @each $key, $value in $map {
    --#{$key}: #{$value};
  }
}

@mixin generate-theme-variable-rgb-from-map($map, $name-list: []) {
  @each $key, $value in $map {
    @if index($name-list, $key) != null {
      --#{$key}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
    }
  }
}

@mixin generate-theme-variable-rgb-from-map($map, $name-list: []) {
  @each $key, $value in $map {
    @if index($name-list, $key) != null {
      --#{$key}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
    }
  }
}

@mixin generate-theme-variable-rgb-from-map($map, $name-list: []) {
  @each $key, $value in $map {
    @if index($name-list, $key) != null {
      --#{$key}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
    }
  }
}
