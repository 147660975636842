// Add main-container to be more specific than Angular material rules
// Centralising CSS rules as mat-select expects a certain height, when we change height the text should align.

.main-container {
  letter-spacing: 0;

  .mat-mdc-select-trigger {
    height: 100%;
  }

  .mat-mdc-select-value-text {
    letter-spacing: 0;
    align-items: center;
    display: flex;
    height: 100%;
  }
}

// Add space between expanded and unxpanded mat-select so expanded options don't block the unexpanded options

.cdk-overlay-container {
  .cdk-overlay-pane {
    &:not(.mat-mdc-select-panel-above),
    &:not(.mat-mdc-auto-complete-panel-above) {
      .mdc-menu-surface {
        &.mat-mdc-select-panel,
        &.mat-mdc-autocomplete-panel {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          margin-top: 6px;
          padding: 0;
        }
      }
    }

    &.mat-mdc-select-panel-above,
    &.mat-mdc-autocomplete-panel-above {
      .mdc-menu-surface {
        &.mat-mdc-select-panel,
        &.mat-mdc-autocomplete-panel {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          margin-bottom: 6px;
          padding: 0;
        }
      }
    }
  }
}

.mat-mdc-select {
  .mat-mdc-select-value {
    color: var(--mat-select--value-text-color, var(--text));

    .mat-mdc-select-placeholder {
      color: var(--mat-select--placeholder-color, var(--grey-400));
    }
  }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  border: 1px solid var(--mat-select--opened-border-color, transparent);

  .mat-mdc-option {
    // option text color
    .mdc-list-item__primary-text {
      color: var(--mat-select--option-text-color, var(--text));
    }

    span {
      letter-spacing: 0;
      font: var(--rd-mat-select--typeface, var(--text-desktop));
    }

    &.mdc-list-item--selected {
      // selected option background color
      &:not(.mat-mdc-option-multiple) {
        background-color: var(--mat-select--background-color, var(--grey-100));
      }

      // selected option text color
      .mdc-list-item__primary-text {
        color: var(--mat-select--active-option-text-color, var(--primary-100));
      }

      span {
        font: var(
          --rd-mat-select--active-option-typeface,
          var(--text-emphasis-1-mobile)
        );
      }
    }
  }

  // selected value text color
  .mat-mdc-select-value-text {
    color: var(--mat-select--option-value-color, var(--grey-400));
  }
}

.mat-select-invalid {
  border-color: var(--mat-select--invalid-border-color, var(--error-100));
}
